<template>
    <main class="d-flex flex-column justify-content-center align-items-center hv-100 ml-3 mr-3">
        <div class="text-center"> 
            <div class="m-3 text-center">
                <a href="/"><img :src="logo" width="250" alt="logo"></a>
            </div>
            <template v-if="!error">
                <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </template>
            <div class="text-white">
                {{message}}
            </div>
        </div>
    </main>
</template>

<script>
import {api} from '../api'

export default {
    data() {
        return {
            error: false,
            message: 'Verificando, aguarde...'
        }
    },
    methods: {
       async validate() {

           const self = this;
           const rex = /^[a-z0-9]+$/g;
           const code = self.seralizeString(self.$route.params.code);

           await new Promise(r => setTimeout(r, 3000));

           if (!rex.test(code) || code.toString().length < 10 || code.toString().length > 10) {
               self.error = true;
               self.message = 'O código de ativação é inválido!';
           } else {
               api.get(`validate/${code}`).then((response) => {
                   switch(response.data.result) {
                       case 'success':
                           self.createSession(response.data);
                       break;
                       default: 
                           self.error = true;
                           self.message = response.data.message;
                       break;
                   }
               }).catch((error) => {
                   try {
                        self.error = true;
                        self.message = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.error = true;
                        self.message = self.network_erros[408]['message'];
                    }
               });
           }
       },
       seralizeString(str) {
           return str.toString().toLowerCase();
       },
       setItem(key, value) {
            return Promise.resolve().then(() => {
                window.sessionStorage.setItem(key, value);
            });
       },
       createSession(data) {
           this.setItem('account', JSON.stringify({
               user: data.user,
               message: data.message
           })).then(() => {
               window.location.href = '/login';
           });
       },
    },
    created() {
        this.validate();
    }
}
</script>

<style scoped>
.hv-100 {
    height: 100vh;
}
</style>