<template>
    <div class="home-main">
        <WebNavbar />
        <main class="main">
            <div class="container-fluid" :class="{'h-100': loading || message}">
                <template v-if="loading">
                    <div class="d-flex justify-content-center align-items-center h-100">
                        <div v-html="lds_ring"></div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="message">
                        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                            <div class="display-5">
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            <small>{{ message }}</small>
                        </div>
                    </template>
                    <template v-else>
                        <div class="pt-3">
                            <nav class="nav nav-pills nav-fill nav-justified" :class="{'mobile': platform == 'mobile'}">
                                <a class="nav-item nav-link" :class="{'active': cat == 'account'}" id="account-tab" data-toggle="tab" href="#account" role="tab" aria-controls="account" aria-selected="true">Conta</a>
                                <a class="nav-item nav-link" id="extract-tab" data-toggle="tab" href="#extract" role="tab" aria-controls="extract" aria-selected="false">Extrato</a>
                                <a class="nav-item nav-link" id="withdraw-money-tab" data-toggle="tab" href="#withdraw-money" role="tab" aria-controls="withdraw-money" aria-selected="false">Saque</a>
                                <a class="nav-item nav-link" :class="{'active': cat == 'deposit'}" id="deposit-tab" data-toggle="tab" href="#deposit" role="tab" aria-controls="deposit" aria-selected="false">Depositar</a>
                            </nav>
                        </div><!-- /p-3 -->
                        <template v-if="accountLoad">
                            <div class="tab-content mt-3" id="myTabContent" :style="{height: screen_innerHeight + 'px'}">
                                <div class="tab-pane fade" :class="{'show active': cat == 'account'}" id="account" role="tabpanel" aria-labelledby="account-tab">
                                    <div class="card">
                                        <div class="tab-content" id="myTabContentAccount">
                                            <nav class="nav nav-tabs" :class="{'mobile': platform == 'mobile'}">
                                                <a class="nav-item nav-link active" id="personal-data-tab" data-toggle="tab" href="#personal-data" role="tab" aria-controls="personal-data" aria-selected="true">Dados pessoais</a>
                                                <a class="nav-item nav-link" id="bank-data-tab" data-toggle="tab" href="#bank-data" role="tab" aria-controls="bank-data" aria-selected="false">Dados bancários</a>
                                                <a class="nav-item nav-link" id="affiliate-tab" data-toggle="tab" href="#affiliate" role="tab" aria-controls="affiliate" aria-selected="false">Indique e ganhe</a>
                                                <a class="nav-item nav-link" id="edit-account-tab" data-toggle="tab" href="#edit-account" role="tab" aria-controls="edit-account" aria-selected="false">Editar conta</a>
                                            </nav>
                                            <div class="tab-pane fade show active" id="personal-data" role="tabpanel" aria-labelledby="personal-data-tab">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-3">Nome</div>
                                                        <div class="col-sm-9 text-muted text-truncate">{{ account.cliente.nome }} {{ account.cliente.sobrenome }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">CPF</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.cpf }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">E-mail</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.email }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Celular</div>
                                                        <div class="col-sm-9 text-muted">{{ account.contato | formatPhone }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Estado</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.estado }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Cidade</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.cidade }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Data de nascimento</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.data_nascimento | formatBirthDate }}</div>
                                                    </div><!-- /row -->
                                                </div><!-- /card-body -->
                                            </div><!-- /tab-pane -->
                                            <div class="tab-pane fade" id="bank-data" role="tabpanel" aria-labelledby="bank-data-tab">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-3">Banco</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.dados_bancarios.banco }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Agência</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.dados_bancarios.agencia }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Conta</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.dados_bancarios.conta }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Dígito</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.dados_bancarios.digito }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Tipo de conta</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.dados_bancarios.tipo }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Tipo de chave pix</div>
                                                        <div class="col-sm-9 text-muted">{{ pixType(account.cliente.dados_bancarios.pix_tipo) }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Chave pix</div>
                                                        <div class="col-sm-9 text-muted">{{ account.cliente.dados_bancarios.pix ? account.cliente.dados_bancarios.pix : 'Sem chave pix' }}</div>
                                                    </div><!-- /row -->
                                                </div><!-- /card-body -->
                                            </div><!-- /tab-pane -->
                                            <div class="tab-pane fade" id="affiliate" role="tabpanel" aria-labelledby="affiliate-tab">
                                                <div class="card-body">
                                                    <div class="row align-items-center">
                                                        <div class="alert alert-warning mb-0 w-100">
                                                            <template v-if="platform == 'mobile'">
                                                                <div class="display-3 text-center">
                                                                    <i class="fas fa-gift"></i>
                                                                </div>
                                                            </template>
                                                            <span>Compartilhe o seu link com seus amigos, e ganhe uma <b>comissão</b> caso ele se cadastre e efetue o <b>primeiro depósito</b> através do seu link.</span>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Meu link</div>
                                                        <div class="col-sm-9">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend" role="button" @click="copyClipboard('my-link')">
                                                                    <span class="input-group-text" id="inputGroupPrepend3">
                                                                        <i class="fas fa-copy mr-2"></i>Copiar
                                                                    </span>
                                                                </div>
                                                                <input type="text" :value="`${site}/affiliate/${account.ref_id}`" ref="my-link" class="form-control" autocomplete="off" spellcheck="false" readonly>
                                                            </div>
                                                        </div>
                                                    </div><!-- /row -->
                                                </div><!-- /card-body -->
                                            </div><!-- /tab-pane -->
                                            <div class="tab-pane fade" id="edit-account" role="tabpanel" aria-labelledby="edit-account-tab">
                                                <div class="card-body">
                                                    <div class="row align-items-center">
                                                        <div class="col-sm-3">Celular</div>
                                                        <div class="col-sm-9">
                                                            <the-mask class="form-control" id="tel" v-model="account.contato" :mask="'(##) # ####-####'" autocomplete="off" spellcheck="false" required />
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Banco</div>
                                                        <div class="col-sm-9">
                                                            <select class="form-control" name="bank" id="bank" v-model="account.cliente.dados_bancarios.banco">
                                                                <option v-for="(item, index) in banks" :value="item.Name" :selected="account.cliente.dados_bancarios.banco == item.Name" :key="index">{{ item.Name }}</option>
                                                            </select>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Agência</div>
                                                        <div class="col-sm-9">
                                                            <input type="text" v-model="account.cliente.dados_bancarios.agencia" class="form-control" autocomplete="off" spellcheck="false" required>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Conta</div>
                                                        <div class="col-sm-9">
                                                            <input type="text" v-model="account.cliente.dados_bancarios.conta" class="form-control" autocomplete="off" spellcheck="false" required>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Dígito</div>
                                                        <div class="col-sm-9">
                                                            <input type="text" v-model="account.cliente.dados_bancarios.digito" class="form-control" autocomplete="off" spellcheck="false" required>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Tipo de conta</div>
                                                        <div class="col-sm-9">
                                                            <select class="form-control" name="type" id="type" v-model="account.cliente.dados_bancarios.tipo">
                                                                <option value="Corrente" :selected="account.cliente.dados_bancarios.tipo == 'Corrente'">Corrente</option>
                                                                <option value="Poupança" :selected="account.cliente.dados_bancarios.tipo == 'Poupança'">Poupança</option>
                                                            </select>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Tipo de chave pix</div>
                                                        <div class="col-sm-9">
                                                            <select class="form-control" id="pix_type" v-model="account.cliente.dados_bancarios.pix_tipo" required>
                                                                <option value="cpf_cnpj" :selected="account.cliente.dados_bancarios.pix_tipo == 'cpf_cnpj'">CPF/CNPJ</option>
                                                                <option value="email" :selected="account.cliente.dados_bancarios.pix_tipo == 'email'">E-mail</option>
                                                                <option value="celular" :selected="account.cliente.dados_bancarios.pix_tipo == 'celular'">Celular</option>
                                                                <option value="chave" :selected="account.cliente.dados_bancarios.pix_tipo == 'chave'">Chave aleatória</option>
                                                            </select>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col-sm-3">Chave pix</div>
                                                        <div class="col-sm-9">
                                                            <template v-if="account.cliente.dados_bancarios.pix_tipo == 'cpf_cnpj'">
                                                                <the-mask class="form-control" id="pix" v-model="account.cliente.dados_bancarios.pix" :mask="['###.###.###-##', '##.###.###/####-##']" autocomplete="off" spellcheck="false" required />
                                                            </template>
                                                            <template v-else-if="account.cliente.dados_bancarios.pix_tipo == 'celular'">
                                                                <the-mask class="form-control" id="pix" v-model="account.cliente.dados_bancarios.pix" :mask="'(##) # ####-####'" autocomplete="off" spellcheck="false" required />
                                                            </template>
                                                            <template v-else>
                                                                <input :type="account.cliente.dados_bancarios.pix_tipo == 'email' ? 'email' : 'text'" class="form-control" id="pix" v-model="account.cliente.dados_bancarios.pix" autocomplete="off" spellcheck="false" required />
                                                            </template>
                                                        </div>
                                                    </div><!-- /row -->
                                                    <div class="row align-items-center mt-3">
                                                        <div class="col">
                                                            <button class="btn btn-block" role="button" @click="saveAccount">
                                                                <i class="fas fa-save mr-2"></i>Salvar alterações
                                                            </button>
                                                        </div>
                                                    </div><!-- /row -->
                                                </div><!-- /card-body -->
                                            </div><!-- /tab-pane -->
                                        </div><!-- /tab-content -->
                                    </div><!-- /card -->
                                </div><!-- /tab-pane -->
                                <div class="tab-pane fade" id="extract" role="tabpanel" aria-labelledby="extract-tab">
                                    <div class="card">
                                        <div class="tab-content" id="myTabContentExtract">
                                            <nav class="nav nav-tabs" :class="{'mobile': platform == 'mobile'}">
                                                <a class="nav-item nav-link active" id="main-tab" data-toggle="tab" href="#main" role="tab" aria-controls="main" aria-selected="true">Principal</a>
                                                <a class="nav-item nav-link" id="movements-tab" data-toggle="tab" href="#movements" role="tab" aria-controls="movements" aria-selected="false">Movimentações</a>
                                            </nav>
                                            <div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-3">Saldo</div>
                                                        <div class="col-sm-9 text-muted text-truncate">{{ format_coin(account.credito) }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row" :class="{'align-items-center': platform == 'desktop'}">
                                                        <template v-if="account.bonus == 0">
                                                            <div class="col-sm-3">Bônus</div>
                                                            <div class="col-sm-9 text-muted text-truncate">{{ format_coin(account.bonus) }}</div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="col-3" v-if="platform == 'desktop'">Bônus</div>
                                                            <div :class="platform == 'desktop' ? 'col-9' : 'col'">
                                                                <button class="btn btn-sm btn-success" @click="redeemBonus" :class="{'btn-block': platform == 'mobile'}">
                                                                    <div class="d-flex flex-row align-items-center justify-content-between">
                                                                        <div class="mr-2">
                                                                            <i class="fas fa-gift mr-2"></i>Resgatar bônus
                                                                        </div>
                                                                        <div class="mr-2">
                                                                            <i class="fas fa-horizontal-rule fa-rotate-90"></i> 
                                                                        </div>
                                                                        <div>{{ format_coin(account.bonus) }}</div>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </template>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Saques</div>
                                                        <div class="col-sm-9 text-muted text-truncate">{{ format_coin(account.retiradas) }}</div>
                                                    </div><!-- /row -->
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col-sm-3">Depósitos</div>
                                                        <div class="col-sm-9 text-muted text-truncate">{{ format_coin(account.lancamentos) }}</div>
                                                    </div><!-- /row -->
                                                </div><!-- /card-body -->
                                            </div><!-- /tab-pane -->
                                            <div class="tab-pane fade" id="movements" role="tabpanel" aria-labelledby="movements-tab">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div :class="platform == 'desktop' ? 'col' : 'col-sm-3'">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text" id="basic-addon1">
                                                                        <i class="fas fa-calendar mr-2"></i>Data
                                                                    </span>
                                                                </div>
                                                                <input type="date" class="form-control" id="basic-addon-movements" v-model="date">
                                                            </div>
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col-auto' : 'col-sm-9 mt-3'">
                                                            <button class="btn btn-block" role="button" @click="myMovements">
                                                                <i class="fas fa-search mr-2"></i>Buscar
                                                            </button>
                                                        </div><!-- /col-auto -->
                                                    </div><!-- /row -->
                                                    <template v-if="movements.length == 0 && !isLoading">
                                                        <div class="alert alert-warning text-center mt-3 mb-0">
                                                            <span>{{ movements_message }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="mt-3">
                                                            <template v-if="platform == 'desktop'">
                                                                <div class="timeline" v-for="(item, index) in movements" :key="index">
                                                                    <div class="timeline-container" :class="(index % 2 === 0 ? 'left' : 'right')">
                                                                        <div class="timeline-content">
                                                                            <div class="row">
                                                                                <div class="col text-truncate">
                                                                                    <h6 class="text-capitalize text-warning">{{item.horario | formatDateAndHourAndSeconds}}</h6>
                                                                                </div>
                                                                                <div class="col-auto">
                                                                                    <i class="fas fa-envelope-open-dollar text-success i-as-top" v-if="item.tipo == 1"></i>
                                                                                    <i class="fas fa-money-bill text-success i-as-top" v-if="item.tipo == 2"></i>
                                                                                    <i class="fas fa-badge-dollar text-success i-as-top" v-if="item.tipo == 3"></i>
                                                                                    <i class="fas fa-hand-holding-usd text-success i-as-top" v-if="item.tipo == 4"></i>
                                                                                    <i class="fas fa-check-circle text-success i-as-top" v-if="item.tipo == 5"></i>
                                                                                    <i class="fas fa-usd-circle text-success i-as-top" v-if="item.tipo == 6"></i>
                                                                                    <i class="fas fa-gift text-success" v-if="item.tipo == 10"></i>
                                                                                    <i class="fas fa-dice i-as-top" :class="item.tipo == 8 ? 'text-danger' : 'text-success'" v-if="item.tipo == 8 || item.tipo == 9"></i>
                                                                                </div>
                                                                            </div><!-- /row -->
                                                                            <p class="text-dark">{{ item.info }}</p>
                                                                        </div><!-- /timeline-content -->
                                                                    </div><!-- /timeline-container -->
                                                                </div><!-- /timeline -->
                                                            </template>
                                                            <template v-else>
                                                                <div class="card card-timeline" v-for="(item, index) in movements" :key="index" :class="{'mt-3': index > 0}">
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div class="col text-truncate">
                                                                                <h6 class="text-capitalize text-warning">{{item.horario | formatDateAndHourAndSeconds}}</h6>
                                                                            </div>
                                                                            <div class="col-auto">
                                                                                <h6>
                                                                                    <i class="fas fa-envelope-open-dollar text-success" v-if="item.tipo == 1"></i>
                                                                                    <i class="fas fa-money-bill text-success" v-if="item.tipo == 2"></i>
                                                                                    <i class="fas fa-badge-dollar text-success" v-if="item.tipo == 3"></i>
                                                                                    <i class="fas fa-hand-holding-usd text-success" v-if="item.tipo == 4"></i>
                                                                                    <i class="fas fa-check-circle text-success" v-if="item.tipo == 5"></i>
                                                                                    <i class="fas fa-usd-circle text-success" v-if="item.tipo == 6"></i>
                                                                                    <i class="fas fa-gift text-success" v-if="item.tipo == 10"></i>
                                                                                    <i class="fas fa-dice" :class="item.tipo == 8 ? 'text-danger' : 'text-success'" v-if="item.tipo == 8 || item.tipo == 9"></i>
                                                                                </h6>
                                                                            </div>
                                                                        </div><!-- /row -->
                                                                        <p class="text-dark mb-0">{{ item.info }}</p>
                                                                    </div><!-- /card-body -->
                                                                </div><!-- /card -->
                                                            </template>
                                                        </div><!-- /mt-3 -->
                                                    </template>
                                                </div><!-- /card-body -->
                                            </div><!-- /tab-pane-->
                                        </div><!-- tab-content -->
                                    </div><!-- /card -->
                                </div><!-- /tab-pane -->
                                <div class="tab-pane fade" id="withdraw-money" role="tabpanel" aria-labelledby="withdraw-money-tab">
                                    <div class="card">
                                        <div class="card-body">
                                            <template v-if="withdraw_money_success">
                                                <div class="alert alert-success" role="alert">
                                                    <h4 class="alert-heading">
                                                        <div class="row align-items-center">
                                                            <div class="col text-truncate">Saque em processamento...</div>
                                                            <div class="col-auto">
                                                                <button type="button" class="close" @click="withdraw_money_success = false" data-dismiss="alert" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </h4>
                                                    <hr />
                                                    <p class="mb-0">Valor: <b>R$ {{ format_coin(account_info.valor) }}</b></p>
                                                    <p class="mb-0">Horário: <b>{{ formatDateAndHour(account_info.horario) }}</b></p>
                                                    <p class="mb-0">Chave de segurança: <b>{{ account_info.chave }}</b></p>
                                                    <hr />
                                                    <p class="mb-3">Seu pedido de saque foi realizado com sucesso. Agora é só aguardar a transferência dentro do prazo estabelecido!</p>
                                                    <p class="mb-0">
                                                        <button class="btn btn-block btn-success" @click="generateVoucher" role="button">
                                                            <i class="fas fa-save mr-2"></i>Salvar comprovante
                                                        </button>
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="alert alert-warning" role="alert">
                                                    <h4 class="alert-heading">Sacar saldo</h4>
                                                    <hr />
                                                    <p class="mb-0">Seu saldo atual é: <b>R$ {{ format_coin(account.credito) }}</b></p>
                                                    <p class="mb-0">Valor mínimo do saque é: <b>R$ {{ format_coin(account.valor_minimo_saque) }}</b></p>
                                                    <hr />
                                                    <p class="mb-0 font-bold">{{ account.mensagem_pagamentos }}</p>
                                                    <hr />
                                                    <p class="mb-0 font-bold">Por questões de segurança, a conta do seu banco e chave pix cadastrada, deverão ser no seu nome, caso contrário o saque será recusado e o valor estornado.</p>
                                                </div><!-- /alert -->
                                            </template>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <i class="fas fa-usd-circle"></i>
                                                        </div>
                                                    </div>
                                                        <input class="form-control" v-model="withdraw_money" type="text" placeholder="Informe o valor">
                                                    </div>
                                                </div>
                                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                                <div class="col">
                                                    <button class="btn btn-theme-conclude btn-block" :disabled="withdraw_money == ''" @click="withdrawMoney">
                                                        <i class="fas fa-hand-holding-usd mr-2"></i>Sacar
                                                    </button>
                                                </div>
                                            </div><!-- /row -->
                                        </div><!-- /card-body -->
                                    </div><!-- /card -->
                                </div><!-- /tab-pane -->
                                <div class="tab-pane fade" :class="{'show active': cat == 'deposit'}" id="deposit" role="tabpanel" aria-labelledby="deposit-tab">
                                    <div class="card">
                                        <div class="card-body" :class="{'pb-2' : pay_status == 'approved'}">
                                            <div class="alert alert-warning" role="alert">
                                                <h4 class="alert-heading">Depósito via pix</h4>
                                                <hr />
                                                <template v-if="qrCode">
                                                    <template v-if="pay_status == 'approved'">
                                                        <div class="text-center d-flex justify-content-center align-items-center flex-column h-100">
                                                            <div class="w-100 display-1 text-success">
                                                                <i class="fas fa-badge-check"></i>
                                                            </div>
                                                            <div class="mt-3">
                                                                <div class="alert alert-success" role="alert">
                                                                    <span>Seu pagamento foi aprovado e seus créditos já estão disponíveis!</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="text-center mb-3">
                                                            <img class="rounded" :src="`data:image/jpeg;base64,${qrCodeBase64}`" width="225" height="225" border="0" alt="QR Code">
                                                        </div><!-- /text-center -->
                                                        <span>Escanei o <b>QR Code</b> com o app do seu banco ou {{ platform == 'desktop' ? 'clique' : 'aperte' }} no campo abaixo para copiar o código do pix.</span>
                                                        <div class="input-group mt-3">
                                                            <div class="input-group-prepend" @click="copyClipboard('pix-qrcode')">
                                                                <span class="input-group-text" id="inputGroupPrepend3">
                                                                    <i class="fas fa-copy mr-2"></i>Copiar
                                                                </span>
                                                            </div><!-- /input-group-prepend -->
                                                            <input class="form-control" id="input-pix" ref="pix-qrcode" type="text" :value="qrCode" autocomplete="off" spellcheck="false" readonly>
                                                        </div><!-- /input-group -->
                                                        <hr />
                                                        <p class="mb-0">Tentativas: <strong>{{ pay_total_check_count }}/{{ pay_total_check_limit }}</strong></p>
                                                        <p class="mb-0">Status do pagamento: <strong>{{ pay_status == 'pending' ? 'Pendente' : 'Aprovado' }}</strong></p>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <p class="mb-2">Regras de despósito:</p>
                                                    <p class="mb-0">1. O valor mínimo de depósito é <strong>R$ {{ format_coin(account.valor_minimo_deposito) }}</strong>. <br /> 2. Seus créditos serão liberados assim que seu pagamento for confirmado pelo banco. <br /> 3. Não faça depósito com o valor menor que <strong>R$ {{ format_coin(account.valor_minimo_deposito) }}</strong>. Caso você faça um depósito com o valor menor, seu dinheiro será devolvido.</p>
                                                </template>
                                            </div><!-- /alert -->
                                            <div class="row" v-if="!qrCode">
                                                <div class="col">
                                                    <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <i class="fas fa-usd-circle"></i>
                                                        </div>
                                                    </div>
                                                        <input class="form-control" v-model="deposit_value" type="text" placeholder="Informe o valor">
                                                    </div>
                                                </div>
                                                <div class="w-100 mt-3" v-if="platform == 'mobile'"></div>
                                                <div class="col">
                                                    <button class="btn btn-theme-conclude btn-block" :disabled="deposit_value == ''" @click="depositValue">
                                                        <i class="fas fa-box-usd mr-2"></i>Depositar
                                                    </button>
                                                </div>
                                            </div><!-- /row -->
                                        </div><!-- /card-body -->
                                    </div><!-- /card -->
                                </div><!-- /tab-pane -->
                            </div><!-- /tab-content -->
                        </template>
                    </template>
                </template>
            </div><!-- /container-fluid -->
        </main>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import {api} from '../../api'
import WebNavbar from './WebNavbar'
import {TheMask} from 'vue-the-mask'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'
import * as rasterizeHTML from 'rasterizehtml'

Vue.use(SlimDialog)

const adjust = 145;

export default {
    data() {
        return {
            cat: 'account',
            banks: [],
            account: {},
            accountLoad: false,
            account_info: {
                chave: '',
                valor: '',
                nome: '',
                cpf: '',
                horario: '',
                site_name: ''
            },
            movements: [],
            message: '',
            pay_id: '',
            pay_value: '',
            pay_status: 'pending',
            pay_total_check_count: 0,
            pay_total_check_limit: 50,
            loading: false,
            isLoading: false,
            qrCode: '',
            qrCodeBase64: '',
            deposit_value: '',
            withdraw_money: '',
            withdraw_money_success: false,
            date: moment().format('YYYY-MM-DD'),
            screen_innerHeight: window.innerHeight - adjust,
            movements_message: 'Informe uma data para ver suas movimentações!',
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            site: window.location.protocol == 'https:' ? 'https://' + window.location.hostname : 'http://' + window.location.hostname
        }
    },
    components: {
        TheMask,
        Loading,
        WebNavbar
    },
    methods: {
        myAccount() {
            
            const self = this;

            self.isLoading = true;

            api.get('my-account').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.account = response.data.my_account;
                    break;
                    default:
                        self.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        saveAccount() {

            const self = this;

            if (self.account.contato.toString().trim() == '') {
                self.$dialogs.alert('Informe o seu celular!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (self.account.cliente.dados_bancarios.agencia.toString().trim() == '') {
                self.$dialogs.alert('Informe a agência da sua conta!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (self.account.cliente.dados_bancarios.conta.toString().trim() == '') {
                self.$dialogs.alert('Informe o número da sua conta!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (self.account.cliente.dados_bancarios.digito.toString().trim() == '') {
                self.$dialogs.alert('Informe o dígito da sua conta!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (self.account.cliente.dados_bancarios.digito.toString().trim() == '') {
                self.$dialogs.alert('Informe o dígito da sua conta!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (self.account.cliente.dados_bancarios.pix.toString().trim() == '') {
                self.$dialogs.alert('Informe sua chave pix!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else {

                self.$dialogs.confirm('Você deseja realmente salvar as alterações?', {
                    title: 'Confirmação',
                    cancelLabel: 'Não',
                    okLabel: 'Sim',
                    size: 'sm'
                }).then(res => {

                    if (res.ok) {

                        self.isLoading = true;

                        api.post('my-account', {
                            account: self.account
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    self.$dialogs.alert('As alterações foram salvas com sucesso!', {
                                        title: 'Parabéns',
                                        size: 'sm'
                                    });
                                break;
                                default:
                                    self.$dialogs.alert(response.data.message, {
                                        title: 'Atenção',
                                        size: 'sm'
                                    });
                                break;
                            }
                        }).catch((error) => {
                            try {
                                self.$dialogs.alert(self.network_erros[error.status]['message'], {
                                    title: 'Falha',
                                    size: 'sm'
                                });
                            } catch(e) {
                                self.$dialogs.alert(self.network_erros[408]['message'], {
                                    title: 'Falha',
                                    size: 'sm'
                                });
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        myMovements() {

            const self = this;

            if (!self.date) return;

            self.movements = [];
            self.isLoading = true;
            
            api.get(`my-account/movements/${self.date}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.movements = response.data.movements.sort((a, b) => {
                            if (a.horario > b.horario) return -1;
                            if (a.horario < b.horario) return 1;
                            return 0;
                        });
                    break;
                    default:
                        self.$dialogs.alert(response.data.message, {
                            title: 'Atenção',
                            size: 'sm'
                        });
                    break;
                }
            }).catch((error) => {
                try {
                    self.$dialogs.alert(self.network_erros[error.status]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                } catch(e) {
                    self.$dialogs.alert(self.network_erros[408]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        withdrawMoney() {
            
            const self = this;
            const valor = parseFloat(self.withdraw_money);
            const credito = parseFloat(self.account.credito);

            if (isNaN(valor) || valor == 0) {
                self.$dialogs.alert('Informe um valor válido!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (isNaN(credito) || credito == 0 || credito < valor) {
                self.$dialogs.alert('Você não tem saldo suficiente para realizar o saque!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (valor < self.account.valor_minimo_saque) {
                self.$dialogs.alert(`O valor mínimo do saque é R$ ${self.account.valor_minimo_saque}.`, {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else {

                self.isLoading = true;
                self.account_info.cpf = '';
                self.account_info.nome = '';
                self.account_info.chave = '';
                self.account_info.valor = '';
                self.account_info.horario = '';
                self.withdraw_money_success = false;

                api.post('my-account/withdraw-money', {
                    value: valor
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.withdraw_money = '';
                            self.account.credito -= valor;
                            self.account.retiradas += valor;
                            self.withdraw_money_success = true;
                            self.account_info = response.data.info;
                            self.current_credit = self.format_coin(self.account.credito);
                        break;
                        default:
                            self.$dialogs.alert(response.data.message, {
                                title: 'Atenção',
                                size: 'sm'
                            });
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.$dialogs.alert(self.network_erros[error.status]['message'], {
                            title: 'Falha',
                            size: 'sm'
                        });
                    } catch(e) {
                        self.$dialogs.alert(self.network_erros[408]['message'], {
                            title: 'Falha',
                            size: 'sm'
                        });
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        depositValue() {

            const self = this;
            const valor = parseInt(self.deposit_value);
            const valor_minimo_deposito = parseInt(self.account.valor_minimo_deposito);

            if (isNaN(valor) || valor == 0) {
                self.$dialogs.alert('Informe um valor válido!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else if (valor < valor_minimo_deposito) {
               self.$dialogs.alert(`O valor mínimo para fazer o depósito é R$ ${valor_minimo_deposito}.`, {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else {

                self.pay_id = '';
                self.qrCode = '';
                self.qrCodeBase64 = '';
                self.isLoading = true;
                self.pay_value = valor;
                self.deposit_value = '';
                self.pay_status = 'pending';
                self.pay_total_check_count = 0;

                api.post('my-account/deposit', {
                    valor: valor,
                    cliente: self.account.cliente,
                    codigo_promocional: self.account.codigo_promocional
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.pay_id = response.data.pay_id;
                            self.qrCode = response.data.qrCode;
                            self.qrCodeBase64 = response.data.qrCodeBase64;
                        break;
                        default:
                            self.$dialogs.alert(response.data.message, {
                                title: 'Atenção',
                                size: 'sm'
                            });
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.$dialogs.alert(self.network_erros[error.status]['message'], {
                            title: 'Falha',
                            size: 'sm'
                        });
                    } catch(e) {
                        self.$dialogs.alert(self.network_erros[408]['message'], {
                            title: 'Falha',
                            size: 'sm'
                        });
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        redeemBonus() {

            const self = this;

            self.isLoading = true;

            api.put('my-account').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.account.credito += parseFloat(self.account.bonus);
                        self.current_credit = self.format_coin(self.account.credito);
                        self.$dialogs.alert('Seu bônus foi resgatado com sucesso!', {
                            title: 'Parabéns',
                            size: 'sm'
                        });
                        self.account.bonus = 0;
                        self.current_bonus = '0,00';
                    break;
                    default:
                        self.$dialogs.alert(response.data.message, {
                            title: 'Atenção',
                            size: 'sm'
                        });
                    break;
                }
            }).catch((error) => {
                try {
                    self.$dialogs.alert(self.network_erros[error.status]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                } catch(e) {
                    self.$dialogs.alert(self.network_erros[408]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        checkPaymentPix() {

            const self = this;

            if (!self.pay_id) return;

            api.get(`my-account/check-pay-pix/${self.pay_id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                    case 'pending':
                        self.checkPaymentStatus(response.data);
                    break;
                    default:
                        self.$dialogs.alert(response.data.message, {
                            title: 'Falha',
                            size: 'sm'
                        });
                    break;
                }
            }).catch((error) => {
                try {
                    self.$dialogs.alert(self.network_erros[error.status]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                } catch(e) {
                    self.$dialogs.alert(self.network_erros[408]['message'], {
                        title: 'Falha',
                        size: 'sm'
                    });
                }
            });
        },
        checkPaymentStatus(data) {

            this.pay_total_check_count += 1;

            let valor = parseInt(this.pay_value);
            let credito = parseFloat(this.account.credito);
            let lancamentos = parseFloat(this.account.lancamentos);

            if (data.result == 'success') {
                this.pay_id = '';
                this.pay_value = '';
                this.pay_status = 'approved';
                this.pay_total_check_count = 0;
                this.account.credito = credito + valor;
                this.account.lancamentos = lancamentos + valor;
                this.current_credit = this.format_coin(this.account.credito);
            } else if (data.result == 'pending' && (this.pay_total_check_count <= this.pay_total_check_limit)) {
                setTimeout(this.checkPaymentPix, 5000);
            }
        },
        generateVoucher() {

            const self = this;
            const iframe = document.createElement('iframe');

            if (self.account_info.chave == '') {
                self.$dialogs.alert('Ocorreu um erro ao gerar o comprovante!', {
                    title: 'Atenção',
                    size: 'sm'
                });
            } else {

                self.isLoading = true;

                const page = `
                    <html>
                        <head>
                            <meta charset="utf-8">
                            <style>html,body{height:100%;width:100%}body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";font-size:1rem;font-weight:400;line-height:1.5;color:#212529;text-align:left;background-color:#faebd7}.ticket{width:450px;height:310px}.clearfix{padding:10px 0}strong{text-transform:uppercase}table{border:0;width:100%.text-center{text-align:center}</style>
                        </head>
                        <body>
                            <div class="ticket">
                                <div class="clearfix">
                                    <center>
                                        <strong>Comprovante</strong>
                                    </center>
                                </div>
                                <table cellpadding="8" cellspacing="0" border="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td bgcolor="#fff">Nome</td>
                                            <td bgcolor="#fff">${self.account_info.nome}</td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#f1f1f1">CPF</td>
                                            <td bgcolor="#f1f1f1">${self.account_info.cpf}</td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#fff">Valor</td>
                                            <td bgcolor="#fff">R$&nbsp;${self.format_coin(self.account_info.valor)}</td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#f1f1f1">Horário</td>
                                            <td bgcolor="#f1f1f1">${self.formatDateAndHour(self.account_info.horario)}</td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#fff">Chave de segurança</td>
                                            <td bgcolor="#fff">${self.account_info.chave}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="12" align="center">
                                                <strong>${self.account_info.site_name}</strong>
                                                <br />
                                                ${self.account_info.chave}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </body>
                    </html>
                `;

                iframe.style.width = '450px';
                iframe.style.height = '320px';
                iframe.src = 'about:blank';
                iframe.style.visibility = 'hidden';         

                document.body.appendChild(iframe);

                iframe.contentWindow.document.write(page);

                setTimeout(() => {

                    let canvas = document.createElement('canvas');
                    let ticket = iframe.contentWindow.document.querySelector('.ticket');

                    canvas.width = ticket.clientWidth;
                    canvas.height = ticket.clientHeight;

                    rasterizeHTML.drawHTML(page, canvas).then(() => {

                        self.isLoading = false;

                        iframe.remove();

                        let link = document.createElement('a');
                        link.download = `Comprovante-${self.account_info.chave}.jpeg`;
                        link.href = canvas.toDataURL('image/jpeg');
                        link.click();
                    });
                }, 100);
            }
        },
        getBanks() {

            const self = this;

            self.isLoading = true;

            api.get('banks').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.banks = self.removeDuplicates(response.data.banks, 'Name').sort((a, b) => {
                            return a.Name.localeCompare(b.Name);
                        }).map((item) => {
                            return {
                                Name: [item.Code, item.Name].join(' - ')
                            }
                        });
                    break;
                    default:
                        self.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        copyClipboard(ref) {
            try {
                navigator.clipboard.writeText(this.$refs[ref]['value']).then(() => {
                    this.$dialogs.alert('O <strong>link</strong> foi copiado com sucesso!', {
                        title: 'Parabéns',
                        size: 'sm'
                    });
                }, () => {
                    this.$dialogs.alert('Não foi possível copiar o <strong>link</strong>!', {
                        title: 'Falha',
                        size: 'sm'
                    });
                });
            } catch(e) {
                this.$dialogs.alert('Ocorreu um erro, atualize a página e tente novamente!', {
                    title: 'Falha',
                    size: 'sm'
                });
            }
        },
        removeDuplicates(array, key) {
            return array.reduce((arr, item) => {
                const removed = arr.filter(i => i[key] !== item[key]);
                return [...removed, item];
            }, []);
        },
        formatDateAndHour(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y HH:mm');
        },
    },
    filters: {
        formatPhone(v) {
            let r = v.replace(/\D/g, "");
            r = r.replace(/^0/, "");

            if (r.length > 11) {
                r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
            } else if (r.length > 7) {
                r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
            } else if (r.length > 2) {
                r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
            } else if (v.trim() !== "") {
                r = r.replace(/^(\d*)/, "($1");
            }
            return r;
        },
        formatBirthDate(date) {
            return `${date.substr(0, 2)}/${date.substr(2, 2)}/${date.substr(4, 4)}`;
        },
        formatDateAndHourAndSeconds(date) {
            return moment(date).tz('America/Recife').format('DD/MM/Y HH:mm:ss');
        },
    },
    watch: {
        pay_id(value) {
            if (value) {
                this.checkPaymentPix();
            }
        },
        account() {
            this.getBanks();
            this.accountLoad = true;
        },
        withdraw_money(value) {
            value = value.replace(/[^0-9.]/g, ''); 
            value = value.replace(/(\..*)\./g, '$1');
            this.withdraw_money = value;
        },
        deposit_value(value) {
            value = value.replace(/\D/g,"");
            value = parseFloat(value).toFixed();
            this.deposit_value = isNaN(value) ? '' : value;  
        },
        'account.cliente.dados_bancarios.conta'(value) {
            this.account.cliente.dados_bancarios.conta = value.replace(/[^0-9]/g, '');
        },
        'account.cliente.dados_bancarios.agencia'(value) {
            this.account.cliente.dados_bancarios.agencia = value.replace(/[^0-9]/g, '');
        },
        'account.cliente.dados_bancarios.digito'(value) {
            this.account.cliente.dados_bancarios.digito = value.replace(/[^0-9]/g, '');
        },
        'account.cliente.dados_bancarios.pix_tipo'(value, old_value) {
            if (value != old_value && old_value != undefined) {
                this.account.cliente.dados_bancarios.pix = '';
            }
        },
        '$route': {
            immediate: true,
            handler() {
                if (this.$route.query.hasOwnProperty('nav')) {
                    if (this.$route.query.nav == 'deposit') {
                        this.cat = 'deposit';
                    } else {
                        this.$router.replace({
                            'query.nav': null
                        });
                    }
                }
            }
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.screen_innerHeight = window.innerHeight - adjust;
        });
    },
    created() {
        this.myAccount();
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    margin-top: 56px;
    height: calc(100% - 56px);
}
.home-main .main .container-fluid .tab-content.mt-3 {
    overflow-y: auto;
    height: calc((100vh + 1px) - 145px);
}
.home-main .main .container-fluid .tab-content.mt-3::-webkit-scrollbar {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray)!important;
    border-radius: 0;
}
.home-main .main .container-fluid .tab-content.mt-3::-webkit-scrollbar-thumb {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray-dark)!important;
}
.home-main .main .container-fluid .nav.mobile {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap!important;
    white-space: nowrap!important;
}

.home-main .main .container-fluid .row .btn:not(.btn-success) {
    color: var(--theme-text);
    background-color: var(--theme);
}
.nav:not(.nav-tabs) .nav-link.active {
    color: var(--theme-text);
    background-color: var(--theme);
}
.timeline .timeline-content,
.timeline::after,
.card-timeline {
    background-color: #e9ecef!important;
}
.timeline .timeline-container.left::before {
    border-color: transparent transparent transparent #e9ecef!important;
}
.timeline .timeline-container.right::before {
    border-color: transparent #e9ecef transparent transparent;
}
</style>