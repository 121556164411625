<template>
    <div class="home-main">
        <WebNavbar />
        <main class="main">
            <div class="container-fluid">
                <div class="row pt-3" :class="{'scroll-horizontal': !showDate && total && platform == 'mobile', 'arrowLeft': arrowShow, 'arrowRight': !arrowShow}" @scroll="onScroll">
                    <template v-if="showDate">
                        <div class="col">
                            <template v-if="platform == 'desktop'">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ status == 1 ? 'Aberta' : status == 2 ? 'Ganhou' : status == 3 ? 'Perdeu' : 'Cancelada' }}
                                        <span class="badge" v-if="total && !loading">
                                            {{ total }}
                                        </span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ref="dropdown-menu">
                                        <a class="dropdown-item" href="javascript:;" v-for="(item, index) in buttons" @click="status = item.status" :class="{'active disabled': status == item.status}" :key="index">
                                            {{ item.label }}
                                        </a>
                                    </div>
                                </div><!-- /dropdown-->
                            </template>
                            <template v-else>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-filter"></i>
                                        </span>
                                    </div>
                                    <select class="form-control form-sm form-bg" @change="changeStatus">
                                        <option v-for="(item, index) in buttons" :selected="status == item.status" :value="item.status" :key="index">
                                            {{ item.label }}
                                            <template v-if="total && !loading && status == item.status">&nbsp;({{ total }})</template>
                                        </option>
                                    </select>
                                </div>
                            </template>
                        </div>
                        <div class="w-100 mb-3" v-if="platform == 'mobile'"></div>
                        <div class="col" data-input-subtitle="Data incial">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-calendar-alt" :class="{'mr-2': platform == 'mobile'}"></i>
                                    </span>
                                </div>
                                <input type="date" class="form-control form-sm form-bg" v-model="initial_date" autocomplete="off" spellcheck="false" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="w-100 mb-3" v-if="platform == 'mobile'"></div>
                        <div class="col" data-input-subtitle="Data final">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-calendar-alt" :class="{'mr-2': platform == 'mobile'}"></i>
                                    </span>
                                </div>
                                <input type="date" class="form-control form-sm form-bg" v-model="final_date" autocomplete="off" spellcheck="false" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="w-100 mb-3" v-if="platform == 'mobile'"></div>
                        <div class="col" v-if="platform == 'mobile'">
                            <div class="btn-group w-100">
                                <button class="btn btn-sm text-uppercase" @click="current_day = 'today'">Hoje</button>
                                <button class="btn btn-sm text-uppercase" @click="current_day = 'week'">Semana</button>
                            </div>
                        </div>
                        <div class="w-100 mb-3" v-if="platform == 'mobile'"></div>
                        <div :class="platform == 'desktop' ? 'col-auto' : 'col pr-0'">
                            <div class="btn-group" :class="{'w-100': platform == 'mobile'}">
                                <button class="btn btn-sm text-uppercase" @click="listBets" :class="{'disabled': initial_date == '' || final_date == '', 'btn-block': platform == 'mobile'}">
                                    <i class="fas fa-search mr-2"></i>Buscar
                                </button>
                            </div><!-- /btn-group -->
                        </div>
                    </template>
                    <template v-else-if="!showDate && total">
                        <div class="col">
                            <template v-if="platform == 'desktop'">
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ status == 1 ? 'Aberta' : status == 2 ? 'Ganhou' : status == 3 ? 'Perdeu' : 'Cancelada' }}
                                        <span class="badge" v-if="total && !loading">
                                            {{ total }}
                                        </span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ref="dropdown-menu">
                                        <a class="dropdown-item" href="javascript:;" v-for="(item, index) in buttons" @click="status = item.status" :class="{'active disabled': status == item.status}" :key="index">
                                            {{ item.label }}
                                        </a>
                                    </div>
                                </div><!-- /dropdown-->
                            </template>
                            <template v-else>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-filter"></i>
                                        </span>
                                    </div>
                                    <select class="form-control form-sm form-bg" @change="changeStatus">
                                        <option v-for="(item, index) in buttons" :selected="status == item.status" :value="item.status" :key="index">
                                            {{ item.label }}
                                            <template v-if="total && !loading && status == item.status">&nbsp;({{ total }})</template>
                                        </option>
                                    </select>
                                </div>
                            </template>
                        </div>
                        <div class="col">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control form-sm form-bg" v-model="search" autocomplete="off" spellcheck="false" placeholder="Buscar por apostador, código..." aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-user"></i>
                                    </span>
                                </div>
                                <select class="form-control form-sm form-bg" @change="searchBetsPunter">
                                    <option value="">Selecionar apostador</option>
                                    <option v-for="(item, index) in listPunters" :value="item.punter" :key="index">
                                        {{ item.punter }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col" :class="{'pr-0': platform == 'mobile'}">
                            <template v-if="platform == 'desktop'">
                                <div class="btn-group w-100">
                                    <button class="btn btn-sm text-uppercase" type="button" v-for="(item, index) in buttons" @click="status = item.status" :class="{'active disabled': status == item.status}" :key="index">
                                        {{ item.label }}
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-block dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ status == 1 ? 'Aberta' : status == 2 ? 'Ganhou' : status == 3 ? 'Perdeu' : status == 4 ? 'Cancelada' : 'Exibir tudo' }}
                                        <span class="badge" v-if="total && !loading">
                                            {{ total }}
                                        </span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ref="dropdown-menu">
                                        <a class="dropdown-item text-uppercase" href="javascript:;" v-for="(item, index) in buttons" @click="status = item.status" :class="{'active disabled': status == item.status}" :key="index">
                                            {{ item.label }}
                                        </a>
                                    </div>
                                </div><!-- /dropdown-->
                            </template>
                        </div>
                    </template>
                    <div class="col-auto">
                        <div class="btn-group" :class="{'w-100': platform == 'mobile'}">
                            <button class="btn btn-sm text-uppercase" :class="{'active': showDate, 'btn-block': platform == 'mobile'}" @click="toggleShowDate">
                                <template v-if="platform == 'desktop'">
                                    <i class="fas fa-calendar-alt mr-2"></i>Por data
                                </template>
                                <template v-else-if="platform == 'mobile' && showDate">
                                    <i class="fas fa-times mr-2"></i>Cancelar
                                </template>
                                <template v-else>
                                    <i class="fas fa-filter mr-2"></i>Filtrar
                                </template>
                            </button>
                        </div><!-- /btn-group -->
                    </div>
                </div><!-- /row -->
                <div class="mt-3 h-100vh" :class="{'d-none': showDate && platform == 'mobile'}">
                    <template v-if="loading">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div v-html="lds_ring"></div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="message">
                            <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="filteredListBets.length">
                                <collection-cluster class="rounded" v-bind="collection" :items="filteredListBets" :style="{height: screen_innerHeight + 'px'}">
                                    <div class="row-bet-item" :class="platform == 'desktop' ? 'row' : 'row-fluid'" slot="item" slot-scope="{ cell, item }" :key="cell.index">
                                        <template v-if="platform == 'desktop'">
                                            <div class="col">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div class="text-style">Código</div>
                                                    <div class="text-truncate text-uppercase">{{ item.code }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div class="text-style">Apostador</div>
                                                    <div class="text-truncate text-uppercase">{{ item.punter }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div class="text-style">Valor</div>
                                                    <div class="text-truncate">R$ {{ format_coin(item.value) }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div class="text-style">Retorno</div>
                                                    <div class="text-truncate">R$ {{ format_coin(item.premium) }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div class="text-style">Palpites</div>
                                                    <div class="text-truncate">{{ item.total_hunches }}</div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div class="text-style">Horário</div>
                                                    <div class="text-truncate">{{ item.time_table }}</div>
                                                </div>
                                            </div>
                                            <div class="w-100"></div>
                                            <div class="col-12">
                                                <div class="btn-group w-100 mb-2 pl-3 pr-3">
                                                    <button class="btn btn-sm text-uppercase" @click="viewBet(item.id)">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div>
                                                                <i class="fas fa-eye"></i>
                                                            </div>
                                                            <div class="text-style small" v-if="platform == 'desktop'">Ver</div>
                                                        </div>
                                                    </button>
                                                    <button class="btn btn-sm text-uppercase" @click="cancelBet(item)" v-if="item.situation == 1">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div>
                                                                <i class="fas fa-ban"></i>
                                                            </div>
                                                            <div class="text-style small" v-if="platform == 'desktop'">Cancelar</div>
                                                        </div>
                                                    </button>
                                                    <button class="btn btn-sm text-uppercase" @click="bettingClosure(item.id, item.code, 1)">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div>
                                                                <i class="fas fa-flag-checkered"></i>
                                                            </div>
                                                            <div class="text-style small" v-if="platform == 'desktop'">Encerrar aposta</div>
                                                        </div>
                                                    </button>
                                                    <button class="btn btn-sm text-uppercase" v-if="platform == 'desktop'" @click="printBet(item)">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div>
                                                                <i class="fas fa-print"></i>
                                                            </div>
                                                            <div class="text-style small" v-if="platform == 'desktop'">Imprimir</div>
                                                        </div>
                                                    </button>
                                                    <button class="btn btn-sm text-uppercase" @click="shareTicket(item.code)">
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <div>
                                                                <i class="fas fa-share"></i>
                                                            </div>
                                                            <div class="text-style small" v-if="platform == 'desktop'">Compartilhar</div>
                                                        </div>
                                                    </button>
                                                </div><!-- /btn-group -->
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="card w-100" :class="{'mt-3': cell.index > 0, 'bg-dark text-white': item.situation == 1, 'bg-success text-white': item.situation == 2, 'bg-danger text-white': item.situation == 3, 'bg-secondary text-white': item.situation == 4}">
                                                <div class="card-header">
                                                    <div class="row align-items-center">
                                                        <div class="col text-uppercase text-truncate text-style">
                                                            <i class="fas fa-hashtag mr-2"></i>{{ item.code }}
                                                        </div>
                                                        <div class="col text-right text-truncate text-style">
                                                            <i class="fas fa-calendar mr-2"></i>{{ item.time_table }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col text-truncate text-style">Apostador</div>
                                                        <div class="col text-truncate text-style">{{ item.punter }}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-truncate text-style">Valor</div>
                                                        <div class="col text-truncate text-style">{{ format_coin(item.value) }}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-truncate text-style">Retorno</div>
                                                        <div class="col text-truncate text-style">{{ format_coin(item.premium) }}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col text-truncate text-style">Palpites</div>
                                                        <div class="col text-truncate text-style">{{ item.total_hunches }}</div>
                                                    </div>
                                                    <hr/>
                                                    <div class="row">
                                                        <div class="col text-truncate text-style">Ao vivo</div>
                                                        <div class="col text-truncate text-style">{{ item.live ? 'Sim' : 'Não' }}</div>
                                                    </div>
                                                    <div class="row" v-if="item.account_type == 'vendedor'">
                                                        <div class="col text-truncate text-style">Comissão</div>
                                                        <div class="col text-truncate text-style">{{ calcComission(item.value, item.total_hunches, item.live) }}</div>
                                                    </div>
                                                    <div class="row" v-if="winning_bet_commission > 0">
                                                        <div class="col text-truncate text-style">% do prêmio</div>
                                                        <div class="col text-truncate text-style">{{ calcComissionWinning(item.premium) }}</div>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="w-100 text-center" @click="viewBet(item.id)"><i class="fas fa-eye"></i></div>
                                                        </div>
                                                        <div class="col" v-if="item.situation == 1">
                                                            <div class="w-100 text-center" @click="cancelBet(item)"><i class="fas fa-ban"></i></div>
                                                        </div>
                                                        <div class="col" v-if="isCordova">
                                                            <div class="w-100 text-center" @click="printBet(item)"><i class="fas fa-print"></i></div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="w-100 text-center" @click="bettingClosure(item.id, item.code, 1)"><i class="fas fa-flag-checkered mr-2"></i></div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="w-100 text-center" @click="shareTicket(item.code)"><i class="fas fa-share"></i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- /card -->
                                        </template>
                                    </div>
                                </collection-cluster>
                            </template>
                            <template v-else>
                                <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                    <div class="display-5">
                                        <i class="fas fa-exclamation-circle"></i>
                                    </div>
                                    <template v-if="search">
                                        <small>Não encontramos nenhum resultado para <b>{{ search }}</b>.</small>
                                    </template>
                                    <template v-else>
                                        <small>Nenhuma aposta encontrada.</small>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </template>
                </div><!-- /mt-3 -->
            </div><!-- /container-fluid -->
        </main><!-- /main -->
        <modal name="view-bet" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
            <template v-if="template">
                <div class="modal-topbar">
                    <div class="row align-items-center">
                        <div class="col text-truncate">
                            <i class="fas fa-hashtag align-middle"></i>
                            <span class="text-uppercase text-truncate">
                                {{ betCode }}
                            </span>
                        </div>
                        <div class="col-auto text-right">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div @click="printBet(betData)" class="mr-3" role="button" title="Imprimir" v-if="isCordova || platform == 'desktop'">
                                    <i class="fas fa-print"></i>
                                </div>
                                <template v-if="platform == 'desktop'">
                                    <div @click="generateTicketImage" class="mr-3" role="button" title="Baixar imagem">
                                        <i class="fas fa-download"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    <div @click="shareTicket(betCode)" class="mr-3" role="button" title="Compartilhar">
                                        <i class="fas fa-share"></i>
                                    </div>
                                </template>
                                <div @click="$modal.hide('view-bet')" role="button">
                                    <i class="fal fa-times"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /modal-topbar -->
                <div class="modal-view-bet" v-html="template"></div>
            </template>
        </modal>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import {api} from '../../api'
import VModal from 'vue-js-modal'
import WebNavbar from './WebNavbar'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import * as rasterizeHTML from 'rasterizehtml'
import 'vue-loading-overlay/dist/vue-loading.css'
import CollectionCluster from 'vue-collection-cluster'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(VModal)
Vue.use(SlimDialog)

const adjust = 150;
const format = 'YYYY-MM-DD';
const today = moment().tz('America/Recife').format(format);

export default {
    data() {
        return {
            total: 0,
            bets: [],
            configs: {},
            search: '',
            status: 1,
            message: '',
            offBar: false,
            loading: false,
            showDate: false,
            isLoading: false,
            listPunters: [],
            final_date: today,
            initial_date: today,
            buttons: [{
                status: 1,
                label: 'Aberta'
            },{
                status: 2,
                label: 'Ganhou'
            },{
                status: 3,
                label: 'Perdeu'
            },{
                status: 4,
                label: 'Cancelada'
            },{
                status: 'all',
                label: 'Exibir tudo'
            }],
            collection: {
                heightType: 'automatic',
                itemHeight: 100
            },
            betCode: '',
            betData: '',
            template: '',
            arrowShow: false,
            commissions: {},
            commissions_live: {},
            winning_bet_commission: 0,
            current_day: 'today',
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            screen_innerHeight: window.innerHeight - adjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            site: window.location.protocol == 'https:' ? 'https://' + window.location.hostname : 'http://' + window.location.hostname
        }
    },
    components: {
        Loading,
        WebNavbar,
        CollectionCluster
    },
    methods: {
        listBets() {

            const self = this;

            self.bets = [];
            self.message = '';
            self.loading = true;
            self.showDate = false;

            if (Object.keys(self.configs).length == 0) return self.loadConfigs();

            api.get(`bets/${self.status}/${self.initial_date}/between/${self.final_date}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.commissions = response.data.comissoes,
                        self.commissions_live = response.data.comissoes_ao_vivo;
                        self.winning_bet_commission = response.data.comissao_aposta_premiada;
                        self.bets = self.mountBets(response.data.bets);
                    break;
                    case 'nothing_found':
                        self.message = 'Nenhuma aposta encontrada';
                    break;
                    default:
                        self.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        loadConfigs() {

            const self = this;

            self.isLoading = true;

            api.get('configs').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.configs = response.data.configs;
                        self.user_id = response.data.configs.user_id;
                        self.current_credit = self.format_coin(response.data.configs.credito);
                        self.current_credit_simple = self.format_coin(response.data.configs.credito_simples);
                        self.listBets();
                    break;
                    case 'jwt_expired':
                        self.message = 'Sua sessão expirou, faça o login novamente!';
                    break;
                    default:
                        self.message = 'Ocorreu um problema, recarregue a página!';
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        viewBet(id) {

            const self = this;

            self.isLoading = true;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountView(id, response.data.hunches);
                    break;
                    default:
                        self.showAlert('Atenção!', response.data.message);
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert('Falha!', self.network_erros[error.status]['message']);
                } catch(e) {
                    self.showAlert('Falha!', self.network_erros[408]['message']);
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        cancelBet(item) {

            const self = this;
            
            self.$dialogs.confirm(`Você deseja mesmo cancelar essa aposta <b class="text-uppercase">"${item.code}"</b> ?`, {
                title: 'Cancelar aposta',
                cancelLabel: 'Não',
                okLabel: 'Sim',
                size: 'sm'
            }).then(res => {

                if (res.ok) {

                    self.isLoading = true;

                    api.put(`cancel-bet/${item.id}`).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.showAlert('Parabéns!', 'A aposta foi cancelada com sucesso!');
                            break;
                            default:
                                self.showAlert('Falha!', response.data.message);
                            break;
                        }
                    }).catch((error) => {
                        try {
                            self.showAlert('Falha!', self.network_erros[error.status]['message']);
                        } catch(e) {
                            self.showAlert('Falha!', self.network_erros[408]['message']);
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });
        },
        bettingClosure(id, code, type) {
            this.$dialogs.confirm(`Você está encerrando a aposta: <b class="text-uppercase">${code}</b> <br /><br /> Caso o encerramento se confirme, a empresa ficará com uma parte da premiação. <br /><br /> Você deseja continuar e fazer uma simulação da sua possível premiação?`, {
                title: 'Encerrar aposta',
                cancelLabel: 'Não',
                okLabel: 'Sim',
                size: 'sm'
            }).then(res => {
                if (res.ok) {
                    this.bettingClosureProcess(id, type);
                }
            });
        },
        bettingClosureProcess(id, type) {

            const self = this;

            self.isLoading = true;

            api.get(`betting-closure/${id}/${type}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.showAlert('Parabéns!', 'A aposta foi encerrada com sucesso, aguarde até que a mesma seja definida como premiada!');
                    break;
                    case 'simulator':
                        self.$dialogs.confirm(response.data.message, {
                            title: 'Confirmação de encerramento',
                            cancelLabel: 'Não',
                            okLabel: 'Sim',
                            size: 'sm'
                        }).then(res => {
                            if (res.ok) {
                                self.bettingClosureProcess(response.data.id, 2);
                            }
                        });
                    break;
                    default:
                        self.showAlert('Erro!', response.data.message);
                    break;
                }
            }).catch((error) => {
                try {
                    self.showAlert('Falha!', self.network_erros[error.status]['message']);
                } catch(e) {
                    self.showAlert('Falha!', self.network_erros[408]['message']);
                }                
            }).finally(() => {
                self.isLoading = false;
            });
        },
        mountView(id, hunches) {
            try {
                let html = ``;

                const self = this;
                const bet = self.bets.find((item) => item.id == id);

                if (bet instanceof Object) {

                    bet.hunches = hunches;

                    let anteior_value = 0;
                    let anteior_value_team = 0; 
                    let hunches_order = self.orderByScheduleByBet(hunches);
                    let commission = (bet.premium / 100) * self.configs.comissao_aposta_premiada;

                    for (let key in hunches_order) {
                        let palpite = hunches_order[key];

                        if (anteior_value != palpite.league) {
                            html += `
                            <div class="league">
                                <div>${palpite.league}</div>
                            </div>
                            `;
                        }

                        if (palpite.betbuilder) {

                            html += `<div class="event">`;

                            if (anteior_value_team != palpite.id) {
                                html += `
                                    <div class="item">
                                        <div class="item-col">${palpite.team_home}</div>
                                        <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col">${palpite.team_away}</div>
                                        <div class="item-col">${palpite.live ? 'Ao vivo' : 'Pré-jogo'}</div>
                                    </div>
                                    <div class="item">
                                        <div class="item-col-3 font-bold">Modalidade</div>
                                        <div class="item-col-3 font-bold">Situação</div>
                                        <div class="item-col-3 font-bold">Odds</div>
                                    </div>
                                `;
                            }

                            html += `
                                <div class="item">
                                    <div class="item-col-3">Criar aposta</div>
                                    <div class="item-col-3 ${palpite.situation == 1 || palpite.status == 'g' ? 'hit' : palpite.situation == 2 || palpite.status == 'p' ? 'lose' : 'default'}">${palpite.status == 'g' ? 'Acertou' : palpite.status == 'p' ? 'Perdeu' : palpite.status == 'c' ? 'Cancelado' : self.findSituation(palpite.situation)}</div>
                                    <div class="item-col-3">R$&nbsp;${palpite.odd}</div>
                                </div>
                                <div class="item">
                                    <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                                </div>
                            `;

                            html += `</div>`;

                        } else {
                            html += `
                            <div class="event">
                                <div class="item">
                                    <div class="item-col">${palpite.team_home}</div>
                                    <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                                </div>
                                <div class="item">
                                    <div class="item-col">${palpite.team_away}</div>
                                    <div class="item-col">R$&nbsp;${palpite.odd}</div>
                                </div>
                                <div class="item">
                                    <div class="item-col-3 font-bold">Modalidade</div>
                                    <div class="item-col-3 font-bold">Situação</div>
                                    <div class="item-col-3 font-bold">Ao vivo</div>
                                </div>
                                <div class="item">
                                    <div class="item-col-3">${self.findModality(palpite.sport)}</div>
                                    <div class="item-col-3 ${palpite.situation == 1 || palpite.status == 'g' ? 'hit' : palpite.situation == 2 || palpite.status == 'p' ? 'lose' : 'default'}">${palpite.status == 'g' ? 'Acertou' : palpite.status == 'p' ? 'Perdeu' : palpite.status == 'c' ? 'Cancelado' : self.findSituation(palpite.situation)}</div>
                                    <div class="item-col-3">${palpite.live ? 'Sim' : 'Não'}</div>
                                </div>
                                <div class="item">
                                    <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                                </div>
                            </div>
                            `;
                        }

                        anteior_value = palpite.league;
                        anteior_value_team = palpite.id;
                    }

                    let template = `
                     <div class="ticket-virtual">
                        <div class="header">
                            <div class="item">
                                <div class="logo">
                                    <img src="${self.logo}" border="0" alt="logo" />
                                </div>
                            </div>
                            <div class="item text-right">
                                <div class="font-bold">Código</div>
                                <div>${bet.code}</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <h3 class="text-center ${bet.situation == 1 ? 'd-none' : 'd-block text-underline'}">
                                ${bet.situation == 2 ? 'Aposta premiada' : bet.situation == 3 ? 'Aposta perdida' : bet.situation == 4 ? 'Aposta cancelada' : 'Sem informações'}    
                            </h3>

                            ${bet.account_type == 'vendedor' ? `
                                <div class="wrap-item">
                                    <div>Vendedor</div>
                                    <div>${bet.salesman}</div>
                                </div>
                            ` : ''}
                            
                            <div class="wrap-item">
                                <div>Apostador</div>
                                <div>${bet.punter}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Valor apostado</div>
                                <div>R$&nbsp;${self.format_coin(bet.value)}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Possível prêmio</div>
                                <div>R$&nbsp;${self.format_coin(bet.premium)}</div>
                            </div>
                            ${self.configs.comissao_aposta_premiada != 0 ? `
                                <div class="wrap-item">
                                    <div>Vendedor paga</div>
                                    <div>R$&nbsp;${self.format_coin(bet.premium - commission)}</div>
                                </div>
                            ` : ''}
                            <div class="wrap-item">
                                <div>Horário da aposta</div>
                                <div>${self.convertDate(bet.date) + '&nbsp;-&nbsp;' + bet.hour}</div>
                            </div>
                            <h5 class="text-center">
                                Qtd. Palpites: ${bet.total_hunches}    
                            </h5>
                            <div class="wrap-content">
                                <div class="wrap-content-item">
                                    ${html}
                                </div>
                            </div>
                        </div>
                     </div>
                    `;

                    self.betData = bet;
                    self.betCode = bet.code;
                    self.template = template;
                    self.$modal.show('view-bet');
                } 
                else 
                {
                    self.showAlert('Atenção!', 'Aposta não encontrada!');
                }
            } 
            catch(e) 
            {
                this.showAlert('Atenção!', e.message);
            }
        },
        printBet(data) {
            if (this.platform == 'desktop') {
                this.printDesktop(data, this.configs);
            } else if (this.isCordova) {
                this.printDirectApp(2, data, this.site, this.configs);
            } else {
                this.showAlert('Atenção!', 'Só é possível imprimir utilizando o aplicativo!');
            }
        },
        shareTicket(betCode) {
            if (!betCode) return;

            const code = (betCode).toLowerCase();
            const bet = this.bets.find((item) => item.code == code);

            if (bet instanceof Object) {
                if (this.platform == 'desktop') {
                    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`Acompanhe sua aposta clicando no link abaixo!! \n\n ${this.site}/ticket/${bet.code} \n\n Apostador: ${bet.punter} \n Valor apostado: R$ ${this.format_coin(bet.value)} \n Possível retorno: R$ ${this.format_coin(bet.premium)}`)}`,'_blank');
                } else if (this.isCordova) {
                    this.shareDirectApp(bet, this.site, this.configs);
                } else {
                    window.location.href = `whatsapp://send?text=${encodeURIComponent(`Acompanhe sua aposta clicando no link abaixo!! \n\n ${this.site}/ticket/${bet.code} \n\n Apostador: ${bet.punter} \n Valor apostado: R$ ${this.format_coin(bet.value)} \n Possível retorno: R$ ${this.format_coin(bet.premium)}`)}`;
                }
            } else {
                this.showAlert('Atenção!', 'Aposta não encontrada!');
            }
        },
        generateTicketImage() {
            try {

                if (!this.template) return;

                const self = this;
                const iframe = document.createElement('iframe');
                const {themeStyleColor, themeTextColor, themeForeground} = self.getThemeStyle();

                if (themeStyleColor && themeTextColor && themeForeground) {

                   let page = `
                        <html>
                            <head>
                                <meta charset="utf-8">
                                <style>
                                    @import url(/css/ticket.css);
                                    :root {
                                        --ticket-style-color: ${themeStyleColor};
                                        --ticket-text-color: ${themeTextColor};
                                        --ticket-foreground: ${themeForeground};
                                    }
                                    html, body {
                                        margin: 0;
                                        padding: 0;
                                        width: 100%;
                                        height: auto;
                                        background-color: var(--ticket-style-color);
                                    }
                                    h5 {
                                        margin-top: 0!important;
                                    }
                                </style>
                            </head>
                            <body>
                                ${self.template}
                            </body>
                        </html>
                    `;

                    self.isLoading = true;

                    iframe.style.width = '600px';
                    iframe.style.height = 'auto';
                    iframe.src = 'about:blank';
                    iframe.style.visibility = 'hidden';  

                    document.body.appendChild(iframe);
                    iframe.contentWindow.document.write(page);

                    setTimeout(() => {

                        let canvas = document.createElement('canvas');
                        let ticket = iframe.contentWindow.document.querySelector('.ticket-virtual');

                        canvas.width = ticket.offsetWidth;
                        canvas.height = ticket.offsetHeight;

                        rasterizeHTML.drawHTML(page, canvas).then(() => {

                            self.isLoading = false;

                            iframe.remove();

                            let link = document.createElement('a');
                            link.download = (self.betCode ? `TICKET-${(self.betCode).toUpperCase()}.jpeg` : `${Math.floor(Math.random() * 1000000)}.jpeg`);
                            link.href = canvas.toDataURL('image/jpeg');
                            link.click();
                        });
                    }, 100);
                } 
                else 
                {
                    self.showAlert('Falha!', 'Não foi possível gerar a imagem do comprovante!');
                }
            } 
            catch(e) 
            {
                this.showAlert('Falha!', e.message);
            }
        },
        mountBets(bets) {
            return bets.sort((a, b) => {

                if (a.horario > b.horario) return -1;
                if (a.horario < b.horario) return 1;

                return 0;
            }).map((item) => {
                return {
                    type: 'item',
                    id: item._id,
                    hour: item.hora,
                    date: item.data,
                    code: item.codigo,
                    value: item.valor,
                    live: item.ao_vivo,
                    premium: item.premio,
                    punter: item.apostador,
                    salesman: item.vendedor,
                    hunches: item.palpites,
                    situation: item.situacao,
                    account_type: item.tipo_de_conta,
                    give_money_back: item.dinheiro_devolvido,
                    total_hunches: this.countTotalHunches(item.palpites), 
                    time_table: moment(item.horario).tz('America/Recife').format('DD/MM HH:mm'),
                    schedule: moment(item.horario).tz('America/Recife').format('DD/MM/YYYY HH:mm')
                }
            });
        },
        punterOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.punter === o.punter)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return a.punter.localeCompare(b.punter)
            });
        },
        changeStatus(event) {
            this.status = event.target.value;
        },
        searchBetsPunter(event) {
            this.search = event.target.value;
        },
        convertDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        beforeClose() {
            this.betCode = '';
            this.betData = '';
            this.template = '';
        },
        toggleShowDate() {
            this.showDate = !this.showDate;
        },
        showAlert(title, message) {
            this.$dialogs.alert(message, {
                title, 
                size: 'sm'
            });
        },
        onScroll(e) {
            const scrollLeft = Math.ceil(e.target.scrollLeft);
            const element = document.querySelector('.row.pt-3.scroll-horizontal');
            const elScrollWidth = element.scrollWidth - element.offsetWidth;

            if (scrollLeft == 0) {
                this.arrowShow = false;
            } else if (scrollLeft >= elScrollWidth) {
                this.arrowShow = true;
            }
        },
        calcComission(value, total_hunches, live) {
            try {
                if (live) {
                    let commission = total_hunches < 20 ? this.commissions_live[total_hunches] : this.commissions_live[20];
                    return `${commission}% = R$ ${this.format_coin((value / 100) * commission)}`;
                } else {
                    let commission = total_hunches < 20 ? this.commissions[total_hunches] : this.commissions[20];
                    return `${commission}% = R$ ${this.format_coin((value / 100) * commission)}`;
                }
            } catch(e) {
                return 0;
            }
        },
        calcComissionWinning(premium) {
            try {
                let commission = parseInt(this.winning_bet_commission);
                if (commission > 0) {
                    return `${commission}% = R$ ${this.format_coin((premium / 100) * commission)}`;
                } else {
                    return 0;
                }
            } catch(e) {
                return 0;
            }
        }
    },
    watch: {
        bets(values) {
            this.total = values.length;
            this.listPunters = this.punterOrganizes(values);
        },
        status() {
            this.listBets();
        },
        current_day(value) {
            switch(value) {
                case 'today':
                    this.initial_date = today;
                    this.final_date = today;
                    this.listBets();
                break;
                default:
                    this.initial_date = moment().startOf('isoWeek').format(format);
                    this.final_date = moment().endOf('isoWeek').format(format);
                    this.listBets();
                break;
            }
        }
    },
    computed: {
        filteredListBets() {
            return this.bets.filter((item) => {
                return this.remove_accents(item.punter).toLowerCase().trim().includes(this.remove_accents(this.search).toLowerCase().trim()) || item.code.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.screen_innerHeight = window.innerHeight - adjust;
        });
    },
    created() {
        this.listBets();
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    margin-top: 56px;
    height: calc(100% - 56px);
}
.main .h-100vh {
    height: calc(100vh - 101px);
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn,
.home-main .main .container-fluid .row.pt-3 .col .dropdown .dropdown-toggle,
.home-main .main .container-fluid .row.pt-3 .col .form-control.form-sm.form-bg {
    height: 38px;
    position: relative;
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn .badge,
.home-main .main .container-fluid .row.pt-3 .col .dropdown .dropdown-toggle .badge {
    top: 11px;
    margin-left: 5px;
    position: absolute;
    color: var(--theme-text);
    background-color: var(--theme);
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn.active .badge {
    color: var(--sport-menu-link)!important;
    background-color: var(--foreground)!important;
}
.home-main .main .container-fluid .row .col .form-control.form-sm.form-bg {
    color: var(--sport-menu-link);
    outline: none;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .form-control.form-bg:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.home-main .main .container-fluid .row .col .input-group-text {
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .dropdown .dropdown-toggle,
.home-main .main .container-fluid .row .col .dropdown .dropdown-item.active, 
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:active,
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:focus, 
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:hover {
    color: var(--sport-menu-link);
    outline: none!important;
    box-shadow: none!important;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .dropdown .dropdown-menu {
    width: 100%;
    background-color: var(--bg-navbar);
}
.home-main .main .container-fluid .row.row-bet-item {
    color: var(--sport-menu-link);
    background-color: var(--gray-dark);
}
.home-main .main .container-fluid .row.row-bet-item:not(:first-child) {
    border-top: 1px solid var(--background);
}
.home-main .main .container-fluid .row.row-bet-item .col {
    padding: 0.5rem!important;
}
.home-main .main .container-fluid .row.row-bet-item .col .text-truncate {
    max-width: 80%;
}
.home-main .main .container-fluid .row.row-bet-item .col .text-style {
    color: var(--theme);
}
.home-main .main .container-fluid .collection-cluster {
    overflow-x: hidden;
    overflow-y: auto;
}
.home-main .main .container-fluid .collection-cluster::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: none;
    border-radius: 0;
}
.home-main .main .container-fluid .collection-cluster::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 0;
}
.row.scroll-horizontal {
    overflow-x: auto;
    position: relative;
    white-space: nowrap!important;
    flex-wrap: nowrap!important;
}
.row.scroll-horizontal.overflow-initial {
    overflow: initial;
}
.row.scroll-horizontal .col {
    flex: 0 0 auto!important;
}
.row.scroll-horizontal::before,
.row.scroll-horizontal::after {
    z-index: 1;
    top: 0;
    margin-top: 79px;
    position: fixed;
    width: 10px;
    height: 24px;
    color: var(--theme);
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}
.row.scroll-horizontal.arrowLeft::before {
    left: 1px;
    content: '\f053';
}
.row.scroll-horizontal.arrowRight::after {
    right: 1px;
    content: '\f054';
}
.modal-view-bet {
    width: 100%;
    height: calc(100% - 36.8px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--ticket-style-color);
}
.modal-view-bet::-webkit-scrollbar {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray)!important;
    border-radius: 0;
}
.modal-view-bet::-webkit-scrollbar-thumb {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray-dark)!important;
}
[data-input-subtitle]::before {
    z-index: 1!important;
    text-transform: uppercase!important;;
    color: var(--sport-menu-link)!important;
    background-color: var(--foreground)!important;
}
</style>