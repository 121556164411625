<template>
    <div class="home-main">
        <WebNavbar />
        <main class="main">
            <div class="container-fluid" :class="{'h-100': loading || message}">
                <template v-if="loading">
                    <div class="d-flex justify-content-center align-items-center h-100">
                        <div v-html="lds_ring"></div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="message">
                        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                            <div class="display-5">
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            <small>{{ message }}</small>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row pt-3">
                            <div class="col">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control form-sm form-bg" @input="searchGame" autocomplete="off" placeholder="Buscar jogos" spellcheck="false" aria-describedby="basic-addon1">
                                </div>
                            </div><!-- /col -->
                            <div class="col">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fas fa-filter"></i>
                                        </span>
                                    </div>
                                    <select class="form-control form-sm form-bg" @change="changeProvider">
                                        <option value="" selected>Todos os provedores</option>
                                        <option class="text-capitalize" v-for="provider in providers" :value="provider" :key="provider" :selected="search == provider">
                                            {{ provider }}
                                        </option>
                                    </select>
                                </div>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <div class="w-100 loby-casino-providers" :class="{'mobile': platform == 'mobile'}">
                            <div class="pt-3">
                                <ul class="nav nav-pills nav-fill">
                                    <li class="nav-item" v-for="(category, index) in categories" :key="index">
                                        <a class="nav-link text-style" :class="{'active': category.value == search}" @click="search = category.value" href="javascript:;">
                                            {{ category.label }}
                                        </a>
                                    </li>
                                </ul>
                            </div><!-- /p-3 -->
                        </div><!-- /w-100 -->
                        <div :class="platform == 'desktop' ? 'row' : 'row-fluid'">
                            <div class="col-4 mt-3" v-if="platform == 'desktop'">
                                <div class="loby-casino-box w-100 d-flex flex-column align-items-center justify-content-center p-3 rounded" :style="{height: screen_innerHeight + 'px'}">
                                    <div class="loby-casino-box-logo mb-3">
                                        <img width="150" :src="logo" alt="">
                                    </div>
                                    <div class="loby-casino-box-image">
                                        <VueSlickCarousel v-bind='{"dots": false, "arrows": false, "speed": 500, "focusOnSelect": true, "infinite": true, "autoplay": true, "centerMode": false, "lazyLoad": "ondemand"}'>
                                            <div v-for="(image, index) in imagesSlide" :key="index">
                                                <img class="img-fluid" :src="image" :alt="index">
                                            </div>
                                        </VueSlickCarousel>
                                    </div>
                                </div><!-- /w-100 -->
                            </div><!-- /col-4 -->
                            <div :class="platform == 'desktop' ? 'col-8' : 'w-100'">
                                <div class="mt-3 loby-casino-games" :class="platform == 'desktop' ? 'desktop' : 'mobile'" :style="{height: screen_innerHeight + 'px'}">
                                    <div class="w-100">
                                        <VueSlickCarousel v-bind='slideProvidersConfig'>
                                            <div class="card bg-dark text-center p-2 rounded-0" role="button" v-for="provider in providersSlide" :key="provider" @click="search = provider">
                                                <img class="d-inline-block" height="50" :src="`/images/providers/${provider}.png`" :alt="provider" border="0">
                                            </div>
                                        </VueSlickCarousel>
                                    </div><!-- /w-100 -->
                                    <div class="mb-3" v-for="(game, key) in filteredListGames" :key="key">
                                        <div class="loby-casino-title position-sticky mb-2">
                                            <div class="d-flex flex-row justify-content-between">
                                                <span>{{ game.cat }}</span>
                                                <span>{{ game.total }}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap align-items-center" :class="{'justify-content-between': game.cat == 'slots' || game.cat == 'Cassino ao vivo' || game.cat == 'Loteria'}">
                                            <div class="loby-casino-item" v-for="(item, index) in game.games" :key="index" @click="openGame(item.id, item.name)" role="button">
                                                <div v-lazy-container="{ selector: 'img', error: '/images/casino-default.jpg', loading: '/images/1495.gif' }" class="loby-casino-image">
                                                    <img :data-src="item.img" :alt="item.name" border="0">
                                                </div><!-- /loby-casino-image -->
                                                <div class="loby-casino-legend">
                                                    <span>{{ item.label == 'aviator' ? 'Spribe' : item.label == 'jetx' ? 'SmartSoft Gaming' : item.title ? item.title : game.cat }}</span>
                                                    <legend>{{ item.name }}</legend>
                                                </div>
                                            </div><!-- /loby-casino-item -->
                                        </div><!-- /d-flex -->
                                    </div><!-- /card -->
                                </div><!-- /mt-3 h-100vh -->
                            </div><!-- /col-8 -->
                        </div><!-- /row -->
                    </template>
                </template>
            </div><!-- /container-fluid -->
            <template v-if="iframe_open">
                <div class="iframe-fixed" :class="{'iframe-loading': iframe_loading, 'mobile': platform == 'mobile'}">
                    <div class="container-fluid">
                        <div class="row pt-2 pb-2">
                            <div class="col text-truncate">
                                <i class="fas fa-dice mr-2"></i>{{ gameName }}
                            </div>
                            <div class="col-auto" @click="toggleFullScreen" role="button" v-if="platform == 'desktop'">
                                <i :class="fullScreen ? 'fas fa-compress' : 'fas fa-expand-wide'"></i>
                            </div>
                            <div class="col-auto" @click="iframe_open = false" role="button">
                                <i class="fas fa-times"></i>
                            </div>
                        </div>
                    </div>
                    <div :class="`embed-responsive embed-responsive-${screen_ratio}`" :style="{height: platform == 'desktop' ? screen_adjust_height + 'px' : screen_adjust_height_mobile + 'px'}" frameborder="0" allow="fullscreen" allowfullscreen>
                        <iframe class="embed-responsive-item" :src="iframe_url"  @load="iframe_loading = false"></iframe>
                    </div><!-- /iframe-game -->
                </div>
            </template>
        </main>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import {api} from '../../api'
import WebNavbar from './WebNavbar'
import VueLazyload from 'vue-lazyload'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)
Vue.use(VueLazyload)

const adjust = 200;
const adjust_height = 96;
const adjust_height_mobile = 56;

export default {
    data() {
        return {
            pipe: 0,
            search: '',
            message: '',
            user_name: '',
            loading: false,
            isLogged: false,
            isLoading: false,
            fullScreen: false,
            gameName: '',
            gamesList: {},
            providers: [],
            imagesSlide: [],
            providersSlide: [],
            categories: [{
                label: 'Todos',
                value: ''
            }],
            translate: {
                live_dealers: 'Cassino ao vivo', 
                sport: 'Esportes', 
                video_poker: 'Vídeo Poker',
                lottery: 'Loteria',
                roulette: 'Roleta',
                card: 'Cartas',
                arcade: 'Video Games',
                fast_games: 'Destaques'
            },
            slideProvidersConfig: {
                "dots": false,
                "arrows": false,
                "autoplay": true,
                "focusOnSelect": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "touchThreshold": 5
            },
            iframe_url: '',
            iframe_open: false,
            iframe_loading: true,
            screen_ratio: '16by9',
            screen_innerHeight: window.innerHeight - adjust,
            screen_adjust_height: window.innerHeight - adjust_height,
            screen_adjust_height_mobile: window.innerHeight - adjust_height_mobile,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Loading,
        WebNavbar,
        VueSlickCarousel
    },
    methods: {
        getGames() {
            
            const self = this;

            self.message = '';
            self.loading = true;

            api.get('casino/list').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.loja = response.data.loja;
                        self.cassino = response.data.cassino;
                        self.cadastro = response.data.cadastro;
                        self.providers = response.data.providers;
                        self.imagesSlide = response.data.imagesSlide;
                        self.providersSlide = response.data.providersSlide;
                        self.gamesList = self.orderCategory(response.data.games, response.data.favorites);
                    break;
                    default:
                        self.loja = response.data.loja;
                        self.cassino = response.data.cassino;
                        self.message = response.data.message;
                        self.cadastro = response.data.cadastro;
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        openGame(id, name) {

            const self = this;

            if (!self.isLogged) {
                self.$dialogs.alert(`
                    <div class="d-flex flex-column align-items-center justify-content-center h-100">
                        <div class="display-3">
                            <i class="fas fa-user-lock"></i>    
                        </div>
                        <div>Para jogar no cassino, entre ou cadastre-se.</div>
                        <div class="w-100 mt-2">
                            <div class="btn-group w-100" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-secondary" onclick="location.href='/login?callback=cassino'">Entrar</button>
                                <button type="button" class="btn btn-secondary" onclick="location.href='/register'">Cadastrar</button>
                            </div>    
                        </div>
                    </div>
                `, {
                    okLabel: 'Fechar',
                    title: 'Atenção'
                });
            } else {

                self.iframe_url = '';
                self.gameName = name;
                self.isLoading = true;
                self.iframe_loading = true;

                api.get(`casino/open-game/${id}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.openModal(response.data.body);
                        break;
                        default:
                            self.$dialogs.alert(response.data.message, {
                                title: 'Atenção',
                                size: 'sm'
                            });
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.$dialogs.alert(self.network_erros[error.status]['message'], {
                            title: 'Falha',
                            size: 'sm'
                        });
                    } catch(e) {
                        self.$dialogs.alert(self.network_erros[408]['message'], {
                            title: 'Falha',
                            size: 'sm'
                        });
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        openModal(data) {
            this.iframe_url = data.content.game.url;
            this.screen_ratio = data.content.game.width == '1' ? '16by9' : '4by3';
            this.iframe_open = true;
        },
        toggleFullScreen() {
            this.fullScreen = !this.fullScreen;
        },
        orderCategory(games, favorites) {

            const categories = {};

            games.sort((a, b) => {
                if (favorites.includes(a.name)) return -1;
                if (favorites.includes(b.name)) return 1;
                return 0;
            }).forEach((item) => {

                const category = item.categories ? this.translate[item.categories] == undefined ? item.categories : this.translate[item.categories] : '';

                if (category) {

                    if (categories[category] == undefined) {

                        this.categories.push({
                            label: category,
                            value: item.categories
                        });
                        
                        categories[category] = {
                            cat: category,
                            games: [],
                            total: 0
                        };
                    }

                    const cat = categories[category];
                    
                    cat.total += 1;
                    cat.games.push(item);
                }
            });

            return Object.keys(categories).map(key => categories[key]);
        },
        changeProvider(e) {
            this.search = e.target.value;
        },
        searchGame(e) {
            if (String(e.target.value).length >= 3) {
                this.search = e.target.value;
            } else {
                this.search = '';
            }
        },
        imageError(event, image) {
            event.target.src = image;
        },
        checkIsLogged() {
            try {
                const {logged, name, pipe} = JSON.parse(window.localStorage.getItem('auth'));
                this.pipe = pipe;
                this.user_name = name;
                this.isLogged = logged;
            } catch(e) {
                this.isLogged = false;
            }
        }
    },
    computed: {
        filteredListGames() {

            let results = [];

            if (this.gamesList.length) {
                this.gamesList.forEach(item => {
                    let games = [];
                    item.games.forEach(game => {
                        if (this.remove_accents(game.name).toLowerCase().trim().includes(this.remove_accents(this.search).toLowerCase().trim()) || this.remove_accents(game.title).toLowerCase().trim().includes(this.remove_accents(this.search).toLowerCase().trim()) || this.remove_accents(game.categories).toLowerCase().trim().includes(this.remove_accents(this.search).toLowerCase().trim())) {
                            games.push(game);
                        }
                    });
                    if (games.length) {
                        results.push({
                            cat: item.cat,
                            games: games,
                            total: games.length
                        });
                    }
                });
            }

            return results;
        }
    },
    watch: {
        filteredListGames() {
            const lobyCasino = document.querySelector('.loby-casino-games'); 
            if (lobyCasino) {
                if (lobyCasino.scrollTop > 0) {
                    setTimeout(() => {
                        lobyCasino.scroll({
                            top: 0, 
                            behavior: 'smooth'
                        });
                    }, 100);
                }
            }
        },
        iframe_open(value) {
            if (!value) {
                this.inGame = false;
                this.iframe_url = '';
                this.getBalanceUser();
            } else {
                this.inGame = true;
            }
        },
        fullScreen(isFull) {
            if ((document.fullScreenElement && document.fullScreenElement !==null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
                if (isFull===false) {
                    return;
                }
                if (document.documentElement.requestFullScreen) {
                    document.documentElement.requestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen();
                }
            } else {
                if (isFull===true) {
                    return;
                }
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.screen_innerHeight = window.innerHeight - adjust;
            this.screen_adjust_height = window.innerHeight - adjust_height;
            this.screen_adjust_height_mobile = window.innerHeight - adjust_height_mobile;
        });
    },
    created() {
        this.getGames();
        this.checkIsLogged();
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    margin-top: 56px;
    height: calc(100% - 56px);
}
.home-main .main .container-fluid .row .col .form-control.form-sm.form-bg {
    color: var(--sport-menu-link);
    outline: none;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .form-control.form-bg:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.home-main .main .container-fluid .row .col .input-group-text {
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .loby-casino-games {
    overflow-x: auto;
    height: calc(100vh - 230px);
}
.home-main .main .container-fluid .loby-casino-games.desktop {
    padding-right: 1rem;
}
.home-main .main .container-fluid .loby-casino-games .d-flex {
    gap: 10px;
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item {
    z-index: 1;
    width: 250px;
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
}
.home-main .main .container-fluid .loby-casino-games.mobile .loby-casino-item {
    width: 100%;
    height: auto;
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item:hover::before {
    content: '\f144';
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 3rem;
    font-family: "Font Awesome 5 Pro";
    background: radial-gradient(rgb(0 0 0 / 62%), transparent);
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item .loby-casino-image img {
    transition: transform .2s;
    object-fit: cover;
    width: 100%;
    height: auto;
    border: 0;
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item:hover .loby-casino-image img {
    transform: scale(1.1);
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item .loby-casino-legend {
    margin: 0;
    height: 50%;
    width: 100%;
    padding: 1rem 1.5rem;
    top: 50%;
    grid-gap: 4px;
    gap: 4px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    position: absolute;
    z-index: 1;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-justify-content: flex-end;
    -moz-box-pack: end;
    justify-content: flex-end;
    color: #fff;
    line-height: 1rem;
    background: -webkit-gradient(linear,left bottom,left top,from(#000),to(transparent));
    background: -webkit-linear-gradient(bottom,#000,transparent);
    background: -moz-linear-gradient(bottom,#000 0,transparent 100%);
    background: linear-gradient(0deg,#000,transparent);
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item .loby-casino-legend span {
    font-size: .75rem;
    font-weight: 400;
    text-transform: capitalize;
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-item .loby-casino-legend legend {
    font-size: 1rem;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-title {
    color: var(--theme);
    font-size: 1.75rem;
    text-transform: uppercase;
    letter-spacing: .1666666667em;
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-title.position-sticky {
    top: -1px;
    z-index: 2;
    background-color: var(--background);
}
.home-main .main .container-fluid .loby-casino-games .loby-casino-title.position-sticky::before {
    content: '';
    top: 1px;
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: var(--foreground);
}
.home-main .main .container-fluid .loby-casino-providers.mobile .nav {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap!important;
    white-space: nowrap!important;
}
.home-main .main .container-fluid .loby-casino-games::-webkit-scrollbar {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray)!important;
    border-radius: 0;
}
.home-main .main .container-fluid .loby-casino-games::-webkit-scrollbar-thumb {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray-dark)!important;
}
.home-main .main .container-fluid .loby-casino-box {
    overflow: hidden;
    position: relative;
    background-color: var(--gray-dark);
}
.home-main .main .container-fluid .loby-casino-box::before {
    content: '';
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--gray-dark);
    background-image: url(/images/giphy.gif);
}
.home-main .main .container-fluid .loby-casino-box .loby-casino-box-logo,
.home-main .main .container-fluid .loby-casino-box .loby-casino-box-image {
    position: relative;
    z-index: 1;
}
.home-main .main .container-fluid .loby-casino-box .loby-casino-box-image {
    width: 350px;
}

.home-main .main .container-fluid .loby-casino-box .loby-casino-box-video {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.home-main .main .container-fluid .loby-casino-box .loby-casino-box-video video {
    min-width: 100%;
    height: 100%;
}
.max-w-80 {
    max-width: 80%;
}
.iframe-fixed {
    top: 56px;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--background);
}
.iframe-fixed.mobile {
    top: 0!important;
    z-index: 9999999999!important;
}
.iframe-fixed.iframe-loading {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/images/1495.gif);
}
.iframe-fixed:not(.mobile) .embed-responsive .embed-responsive-item {
    height: calc(100vh - 96px)!important;
}
.iframe-fixed.mobile .embed-responsive {
    height: 100%!important;
}
.iframe-fixed.mobile .embed-responsive .embed-responsive-item {
    height: calc(100vh - 56px)!important;
}
.iframe-fixed .container-fluid .row {
    text-transform: uppercase;
    color: var(--sport-menu-link);
    background-color: var(--gray-dark);
}
.iframe-fixed .container-fluid .row .col-auto i {
    color: var(--sport-menu-link);
    transition: color .5s ease-out;
    -moz-transition: color .5s ease-out;
    -webkit-transition: color .5s ease-out;
    -o-transition: color .5s ease-out;
}
.iframe-fixed .container-fluid .row .col-auto:hover i {
    color: #fff;
}
.nav-link.active {
    color: var(--theme-text);
    background-color: var(--theme);
}
</style>