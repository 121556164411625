<template>
    <div class="home-main">
        <WebNavbar />
        <main class="main">
            <div class="container-fluid">
                <div class="row pt-3">
                    <template v-if="platform == 'mobile'">
                        <div class="col-12">
                            <div class="menu-filters">
                                <button class="btn btn-sm btn-block bg-button" @click="toggleShowMenuFilters">
                                    <i class="fas fa-filter mr-2"></i>{{ showMenuFilters ? 'Fechar filtros' : 'Abrir filtros' }}
                                </button>
                                <div class="dropdown-menu-filters" v-show="showMenuFilters">
                                    <div class="container">
                                        <div class="row mb-3">
                                            <div class="col">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <i class="fas fa-filter"></i>
                                                        </span>
                                                    </div>
                                                    <select class="form-control form-sm form-bg" @change="changeDay">
                                                        <option value="today" :selected="current_day == 'today'">Hoje</option>
                                                        <option value="week" :selected="current_day == 'week'">Semana</option>
                                                        <option value="month" :selected="current_day == 'month'">Mês</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                        <div class="row mb-3">
                                            <div class="col" data-input-subtitle="Data inicial">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="date" class="form-control form-sm form-bg" v-model="initial_date" autocomplete="off" spellcheck="false" aria-describedby="basic-addon1">
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                        <div class="row mb-3">
                                            <div class="col" data-input-subtitle="Data final">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="date" class="form-control form-sm form-bg" v-model="final_date" autocomplete="off" spellcheck="false" aria-describedby="basic-addon1">
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                        <div class="row">
                                            <div class="col">
                                                <div class="btn-group w-100">
                                                    <button class="btn btn-sm btn-block text-uppercase" @click="searchDate" :class="{'disabled': initial_date == '' || final_date == ''}">
                                                        <i class="fas fa-search mr-2"></i>Buscar
                                                    </button>
                                                </div><!-- /btn-group -->
                                            </div>
                                        </div><!-- /row -->
                                    </div><!-- /container -->
                                </div><!-- /dropdown-menu-filters -->
                            </div><!-- /menu-filters -->
                        </div><!-- /col-12 -->
                    </template>
                    <template v-else>
                        <div class="col">
                            <div class="dropdown">
                                <button class="btn btn-sm btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ current_day == 'today' ? 'Hoje' : current_day == 'week' ? 'Semana' : 'Mês' }}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ref="dropdown-menu">
                                    <a class="dropdown-item" href="javascript:;" @click="current_day = 'today'" :class="{'active disabled': current_day == 'today'}">Hoje</a>
                                    <a class="dropdown-item" href="javascript:;" @click="current_day = 'week'" :class="{'active disabled': current_day == 'week'}">Semana</a>
                                    <a class="dropdown-item" href="javascript:;" @click="current_day = 'month'" :class="{'active disabled': current_day == 'month'}">Mês</a>
                                </div>
                            </div><!-- /dropdown-->
                        </div>
                        <div class="col" data-input-subtitle="Data incial">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-calendar-alt"></i>
                                    </span>
                                </div>
                                <input type="date" class="form-control form-sm form-bg" v-model="initial_date" autocomplete="off" spellcheck="false" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col" data-input-subtitle="Data final">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-calendar-alt"></i>
                                    </span>
                                </div>
                                <input type="date" class="form-control form-sm form-bg" v-model="final_date" autocomplete="off" spellcheck="false" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="btn-group">
                                <button class="btn btn-sm text-uppercase" @click="searchDate" :class="{'disabled': initial_date == '' || final_date == ''}">
                                    <i class="fas fa-search mr-2"></i>Buscar
                                </button>
                            </div><!-- /btn-group -->
                        </div>
                    </template>
                </div><!-- /row -->
                <div class="mt-3 h-100vh">
                    <template v-if="loading">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div v-html="lds_ring"></div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="message">
                            <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="cash">
                                <ul class="nav nav-pills nav-justified mb-3" :class="{'flex-row justify-content-between': platform == 'mobile'}" id="nav-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="geral-tab" data-toggle="tab" href="#geral" role="tab" aria-controls="geral" aria-selected="true"><i class="fas fa-coins mr-2"></i>Geral</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="inplay-tab" data-toggle="tab" href="#inplay" role="tab" aria-controls="inplay" aria-selected="false"><i class="fas fa-signal-stream mr-2"></i>Ao vivo</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="punter-tab" data-toggle="tab" href="#punter" role="tab" aria-controls="punter" aria-selected="false"><i class="fas fa-user mr-2"></i>Apostador</a>
                                    </li>                                     
                                </ul>
                                <div class="collection-cluster" :style="{height: screen_innerHeight + 'px'}">
                                    <div class="tab-content nav-tabContent">
                                        <div class="tab-pane fade show active" id="geral" role="tabpanel" aria-labelledby="geral-tab">
                                            <table class="table table-striped table-dark mb-0 rounded">
                                                <tbody>
                                                    <tr>
                                                        <td>Apurou</td>
                                                        <td>{{ format_coin(cash.total_apuracao) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qtd. Apostas</td>
                                                        <td>{{ format_value(cash.num_apostas) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qtd. Ganhadores</td>
                                                        <td>{{ format_value(cash.num_premios) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qtd. Apostas abertas</td>
                                                        <td>{{ format_value(cash.num_apostas_abertas) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Entradas em aberto</td>
                                                        <td>{{ format_coin(cash.total_apostas_abertas) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo em prêmios</td>
                                                        <td>{{ format_coin(cash.total_premios) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Comissões (pré-jogo)</td>
                                                        <td>{{ format_coin(cash.total_comissao) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Comissões (ao vivo)</td>
                                                        <td>{{ format_coin(cash.total_comissao_ao_vivo) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Comissões (geral)</td>
                                                        <td>{{ format_coin(cash.total_comissao + cash.total_comissao_ao_vivo) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lançamentos</td>
                                                        <td>{{ format_coin(cash.lancamentos) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Retiradas</td>
                                                        <td>{{ format_coin(cash.retiradas) }}</td>
                                                    </tr>
                                                    <tr :class="positiveOrNegative(cash.total_apuracao - cash.total_premios - cash.total_comissao - cash.total_comissao_ao_vivo)">
                                                        <td>Líquido</td>
                                                        <td>{{ format_coin(cash.total_apuracao - cash.total_premios - cash.total_comissao - cash.total_comissao_ao_vivo) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Caixa</td>
                                                        <td>
                                                            <template v-if="cash.caixa == 0">
                                                                {{ format_coin(cash.caixa) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ format_coin((cash.total_apuracao - cash.total_premios - cash.total_comissao - cash.total_comissao_ao_vivo - cash.retiradas) + cash.lancamentos) }}
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tab-pane fade" id="inplay" role="tabpanel" aria-labelledby="inplay-tab">
                                            <template v-if="cash.total_apuracao_ao_vivo == 0">
                                                <div class="d-flex flex-column justify-content-center align-items-center text-center" :class="{'mobile': platform == 'mobile'}">
                                                    <div class="display-5">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </div>
                                                    <small>Nenhum relatório ao vivo.</small>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <table class="table table-striped table-dark rounded mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Apurou</td>
                                                            <td>{{ format_coin(cash.total_apuracao_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Apostas</td>
                                                            <td>{{ format_value(cash.num_apostas_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Ganhadores</td>
                                                            <td>{{ format_value(cash.num_premios_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Apostas abertas</td>
                                                            <td>{{ format_value(cash.num_apostas_abertas_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Entradas em aberto</td>
                                                            <td>{{ format_coin(cash.total_apostas_abertas_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Saldo em prêmios</td>
                                                            <td>{{ format_coin(cash.total_premios_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Saldo em comissões</td>
                                                            <td>{{ format_coin(cash.total_comissao_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr :class="positiveOrNegative(cash.total_apuracao_ao_vivo - cash.total_premios_ao_vivo - cash.total_comissao_ao_vivo)">
                                                            <td>Líquido</td>
                                                            <td>{{ format_coin(cash.total_apuracao_ao_vivo - cash.total_premios_ao_vivo - cash.total_comissao_ao_vivo) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </template>
                                        </div>
                                        <div class="tab-pane fade" id="punter" role="tabpanel" aria-labelledby="punter-tab">
                                            <div class="mb-3">
                                                <div class="row">
                                                    <div class="col" :class="{'pr-0': platform == 'mobile'}">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <select class="form-control form-sm form-bg" @change="searchFinancialByName">
                                                                <option value="">Selecionar apostador</option>
                                                                <option v-for="(item, punter) in orderByPunterByName(cash.apostadores)" :value="punter" :key="punter">
                                                                    {{ punter }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="btn-group">
                                                            <button class="btn btn-block text-uppercase" @click="generateImage(cashFinancialByName)" :class="{'disabled': !cashFinancialByName}">
                                                                <template v-if="platform == 'desktop'">
                                                                    <i class="fas fa-share mr-2"></i>Compartilhar
                                                                </template>
                                                                <template v-else>
                                                                    <i class="fas fa-share"></i>
                                                                </template>
                                                            </button>
                                                        </div><!-- /btn-group -->
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-if="cashFinancialByName">
                                                <table class="table table-striped table-dark rounded mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Apurou</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_apuracao) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Apostas</td>
                                                            <td>{{ format_value(cashFinancialByName.num_apostas) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Ganhadores</td>
                                                            <td>{{ format_value(cashFinancialByName.num_premios) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Saldo em prêmios</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_premios) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Saldo em comissões</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_comissao) }}</td>
                                                        </tr>
                                                        <tr :class="positiveOrNegative(cashFinancialByName.total_apuracao - cashFinancialByName.total_premios - cashFinancialByName.total_comissao)">
                                                            <td>Saldo líquido</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_apuracao - cashFinancialByName.total_premios - cashFinancialByName.total_comissao) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="bg-background" colspan="12">
                                                                <i class="fas fa-signal-stream mr-2"></i>Ao vivo
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Apurou</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_apuracao_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Apostas</td>
                                                            <td>{{ format_value(cashFinancialByName.num_apostas_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Qtd. Ganhadores</td>
                                                            <td>{{ format_value(cashFinancialByName.num_premios_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Saldo em prêmios</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_premios_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Saldo em comissões</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_comissao_ao_vivo) }}</td>
                                                        </tr>
                                                        <tr :class="positiveOrNegative(cashFinancialByName.total_apuracao_ao_vivo - cashFinancialByName.total_premios_ao_vivo - cashFinancialByName.total_comissao_ao_vivo)">
                                                            <td>Saldo líquido</td>
                                                            <td>{{ format_coin(cashFinancialByName.total_apuracao_ao_vivo - cashFinancialByName.total_premios_ao_vivo - cashFinancialByName.total_comissao_ao_vivo) }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                            </template>
                                            <template v-else>
                                                <div class="d-flex flex-column justify-content-center align-items-center text-center punter" :class="{'mobile': platform == 'mobile'}">
                                                    <div class="display-5">
                                                        <i class="fas fa-exclamation-circle"></i>
                                                    </div>
                                                    <small>Selecione um apostador.</small>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                    <div class="display-5">
                                        <i class="fas fa-exclamation-circle"></i>
                                    </div>
                                    <small>Informe a data para buscar o relatório.</small>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </main>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import {api} from '../../api'
import WebNavbar from './WebNavbar'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import * as rasterizeHTML from 'rasterizehtml'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

const adjust = 200;
const format = 'YYYY-MM-DD';
const today = moment().tz('America/Recife').format(format);

export default {
    data() {
        return {
            cash: '',
            message: '',
            offBar: false,
            loading: false,
            isLoading: false,
            final_date: today,
            initial_date: today,
            current_day: 'today',
            cashFinancialByName: '',
            showMenuFilters: false,
            screen_innerHeight: window.innerHeight - adjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
        }
    },
    components: {
        Loading,
        WebNavbar
    },
    methods: {
        searchDate() {

            const self = this;
            const initial_date = self.initial_date;
            const final_date = self.final_date;

            if (initial_date == '') {
                self.showAlert('Atenção!', 'Informe a data inicial!');
            } else if (final_date == '') {
                self.showAlert('Atenção!', 'Informe a data final!');
            } else {

                self.cash = '';
                self.message = '';
                self.loading = true;
                self.showMenuFilters = false;

                api.get(`cash/salesman/${initial_date}/between/${final_date}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.cash = response.data.salesman;
                        break;
                        case 'nothing_found':
                            self.message = 'Nenhum relatório encontrado!';
                        break;
                        default:
                            self.message = response.data.message;
                        break;
                    }
                }).catch((error) => {
                    try {
                        self.message = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.message = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        showAlert(title, message) {
            this.$dialogs.alert(message, {
                title, 
                size: 'sm'
            });
        },
        orderByPunterByName(obj) {
            return Object.keys(obj).sort((a, b) => {
                return this.remove_accents(a.toString()).localeCompare(this.remove_accents(b.toString()))
            }).reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
        },
        searchFinancialByName(event) {
            if (event.target.value) {
                this.cashFinancialByName = this.cash.apostadores[event.target.value];
            } else {
                this.cashFinancialByName = '';
            }
        },
        changeDay(event) {
            this.current_day = event.target.value;
        },
        positiveOrNegative(value) {
            return value < 0 ? 'text-danger' : value == 0 ? 'text-default' : 'text-success';
        },
        generateImage(item) {
            try {

                const self = this;
                const iframe = document.createElement('iframe');
                const date1 = moment(self.initial_date).format('DD/MM/YYYY');
                const date2 = moment(self.final_date).format('DD/MM/YYYY');
                const name = document.querySelector('.tab-pane.fade.active.show[role=tabpanel] select').value;

                if (name) {

                    self.$dialogs.confirm(`Você deseja mesmo compartilhar o comprovante do caixa do apostador: <b class="text-uppercase">"${name}"</b>?`, {
                        title: 'Compartilhar comprovante',
                        cancelLabel: 'Não',
                        okLabel: 'Sim',
                        size: 'sm'
                    }).then(res => {
                        if (res.ok) {
                            const html = `
                                <html>
                                    <head>
                                        <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#faebd7;color:#212529;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                                    </head>
                                    <body class="content">
                                    <br /> 
                                        <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                                    <br />
                                    <table class="table" border="1">
                                        <tbody>
                                            <tr>
                                                <td width="50%" bgcolor="#fff" align="center">Apostador</td>
                                                <td width="50%" bgcolor="#fff" align="center">${name.toUpperCase()}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%">Apostou</td>
                                                <td width="50%">${self.format_coin(item.total_apuracao)}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%">Qtd. Apostas</td>
                                                <td width="50%">${item.num_apostas}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%">Qtd. Prêmios</td>
                                                <td width="50%">${item.num_premios}</td>
                                            </tr>
                                            <tr>
                                                <td width="50%">Saldo em prêmios</td>
                                                <td width="50%">${self.format_coin(item.total_premios)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <h5>
                                        <center>${document.title}</center>
                                    </h5>
                                </body>
                                </html>
                            `;

                            self.isLoading = true;

                            iframe.style.width = '600px';
                            iframe.style.height = '100%';
                            iframe.src = 'about:blank';
                            iframe.style.visibility = 'hidden';         

                            document.body.appendChild(iframe);

                            iframe.contentWindow.document.write(html);

                            setTimeout(() => {

                                let canvas = document.createElement('canvas');
                                let ticket = iframe.contentWindow.document.querySelector('.content');

                                canvas.width = ticket.scrollWidth;
                                canvas.height = ticket.scrollHeight;

                                rasterizeHTML.drawHTML(html, canvas).then(() => {
                                    const imgData = canvas.toDataURL('image/jpeg');
                                    self.isLoading = false;
                                    self.sharePhoto(imgData);
                                    iframe.remove();
                                });
                            }, 100);
                        }
                    });
                }
            } catch(e) {
                this.showAlert(
                    'Atenção!', 
                    'Não foi possível gerar um print desse relatório. Caso o erro ocorra novamente, reinicie seu celular!'
                );
            }
        },
        sharePhoto(imgData) {

            const link = document.createElement('a');

            if (this.isCordova) {
                window.location.href = 'betsnet://share-photo?data=' + imgData;
            } else {
                link.download = `${Math.floor(Math.random() * 1000000)}.jpeg`;
                link.href = imgData;
                link.click();
            }
        },
        toggleShowMenuFilters() {
            this.showMenuFilters = !this.showMenuFilters;
        }
    },
    watch: {
        current_day(value) {
            switch(value) {
                case 'today':
                    this.initial_date = today;
                    this.final_date = today;
                    this.searchDate();
                break;
                case 'week':
                    this.initial_date = moment().startOf('isoWeek').format(format);
                    this.final_date = moment().endOf('isoWeek').format(format);
                    this.searchDate();
                break;
                default:
                    this.initial_date = moment().startOf('month').format(format);
                    this.final_date = moment().endOf('month').format(format);
                    this.searchDate();
                break;
            }
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.screen_innerHeight = window.innerHeight - adjust;
        });
    },
    created() {
        this.searchDate();
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    margin-top: 56px;
    height: calc(100% - 56px);
}
.main .h-100vh {
    height: calc(100vh - 101px);
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn,
.home-main .main .container-fluid .row.pt-3 .col .dropdown .dropdown-toggle,
.home-main .main .container-fluid .row.pt-3 .col .form-control.form-sm.form-bg {
    height: 38px;
    position: relative;
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn .badge,
.home-main .main .container-fluid .row.pt-3 .col .dropdown .dropdown-toggle .badge {
    top: 11px;
    margin-left: 5px;
    position: absolute;
    color: var(--theme-text);
    background-color: var(--theme);
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn.active .badge {
    color: var(--sport-menu-link)!important;
    background-color: var(--foreground)!important;
}
.home-main .main .container-fluid .form-control.form-sm.form-bg {
    color: var(--sport-menu-link);
    outline: none;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .form-control.form-bg:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.home-main .main .container-fluid .input-group-text {
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .dropdown .dropdown-toggle,
.home-main .main .container-fluid .row .col .dropdown .dropdown-item.active, 
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:active,
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:focus, 
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:hover {
    color: var(--sport-menu-link);
    outline: none!important;
    box-shadow: none!important;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .dropdown .dropdown-menu {
    width: 100%;
    background-color: var(--bg-navbar);
}
.home-main .main .container-fluid .row.row-bet-item {
    color: var(--sport-menu-link);
    background-color: var(--gray-dark);
}
.home-main .main .container-fluid .row.row-bet-item:not(:first-child) {
    border-top: 1px solid var(--background);
}
.home-main .main .container-fluid .row.row-bet-item .col {
    padding: 0.5rem!important;
}
.home-main .main .container-fluid .row.row-bet-item .col .text-truncate {
    max-width: 80%;
}
.home-main .main .container-fluid .row.row-bet-item .col .text-style {
    color: var(--theme);
}
.home-main .main .container-fluid .collection-cluster {
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: .25rem;
}
.home-main .main .container-fluid .collection-cluster.mobile {
    height: calc(100vh - 280px)!important;
}
.home-main .main .container-fluid .collection-cluster::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: none;
    border-radius: 0;
}
.home-main .main .container-fluid .collection-cluster::-webkit-scrollbar-thumb {
    background: none;
    border-radius: 0;
}
.home-main .main .container-fluid .collection-cluster .list-group .list-group-item-dark {
    color: var(--theme);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .collection-cluster .list-group .list-group-item-dark .form-check-input {
    margin-top: .5rem;
}
.table-dark {
    color: var(--sport-menu-link);
    background-color: var(--foreground);
}
.table-dark td, .table-dark th, .table-dark thead th {
    border-color: var(--background);
}
.bg-background,
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--theme-text);
    background-color: var(--theme);
}
.row.scroll-horizontal {
    overflow-x: auto;
    white-space: nowrap!important;
    flex-wrap: nowrap!important;
}
.row.scroll-horizontal.overflow-initial {
    overflow: initial;
}
.row.scroll-horizontal .col {
    flex: 0 0 auto!important;
}
.tab-pane .d-flex.flex-column.justify-content-center.align-items-center.text-center {
    height: calc(100vh - 203px);
}
.tab-pane .d-flex.flex-column.justify-content-center.align-items-center.text-center.mobile {
    height: calc(100vh - 280px)!important;
}
.tab-pane .d-flex.flex-column.justify-content-center.align-items-center.text-center.punter {
    height: calc(100vh - 260px);
}
.tab-pane .d-flex.flex-column.justify-content-center.align-items-center.text-center.punter.mobile {
    height: calc(100vh - 335px)!important;
}
.nav.nav-pills.flex-row.justify-content-between {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap!important; 
    width: 100%;
}
.menu-filters {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}
.menu-filters .dropdown-menu-filters {
    width: 100%;
    height: calc(100% - 115px);
    height: calc(100vh - 115px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
    margin-top: 115px;
    background-color: var(--background);
}
[data-input-subtitle]::before {
    z-index: 1!important;
    text-transform: uppercase!important;;
    color: var(--sport-menu-link)!important;
    background-color: var(--foreground)!important;
}
</style>